import React from "react";

const ContactCard = ({ data }) => {
  return (
    <>
      <div
        key={data.id}
        className="bg-cover bg-center bg-no-repeat h-[200px] sm:h-[250px] lg:h-[250px] p-5 rounded-xl shadow-sm hover:shadow-2xl transition ease-in duration-300 relative overflow-x-hidden"
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(32,56,100,8), rgba(26,47,84,0.3), rgba(32,56,100,.28)), url(${data.officeImg})`,
        }}
      >
        <div className="">
          <h1 className="text-white text-xl lg:text-2xl 2xl:text-3xl font-medium">
            {data.country}
          </h1>
        </div>
        <div className="text-white absolute bottom-0 left-0 font-light text-sm sm:text-base md:text-lg lg:text-xl bg-black bg-opacity-40 p-5 rounded-bl-xl lg:grid gap-2">
          <p>{data.address}</p>
          {/* <p>
            Tel: <a href={"tel: " + data.phoneNumber} className="text-tertiary font-semibold">{data.phoneNumber}</a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:info@spinnakeranalytics.com" className="text-tertiary font-semibold">
              info@spinnakeranalytics.com
            </a>
          </p> */}
        </div>
        {/* <div>
          <img loading="lazy" src={data.officeImg} alt={data.officeImgAlt} />
        </div> */}
      </div>
    </>
  );
};

export default ContactCard;
