import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Solutions from "./pages/Solutions";
import Insights from "./pages/Insights";
import Contact from "./pages/Contact";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import About from "./pages/About";
import Blog from "./pages/Blog";
import ScrollToTop from "./components/ScrollToTop";
import Industries from "./pages/Industries";
import ChatBot from "./components/ChatBot";

AOS.init();

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <ChatBot />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/solutions/:solutionSlug"
            element={<Solutions />}
          />
          <Route
            exact
            path="/industries/:industrySlug"
            element={<Industries />}
          />
          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/insights" element={<Insights />} />
          <Route exact path="/insights/:blogSlug" element={<Blog />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
