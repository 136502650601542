import React from "react";
import { Link } from "react-router-dom";

const Products = ({ icon, title, description, url, length }) => {
  
  
  return (
    <>
      <div
        className="flex flex-col gap-5 bg-white border border-gray-200 p-10 pb-28 shadow-md rounded-3xl h-full relative"
        id={url.slice(17)}
      >
        <div className="flex gap-2 sm:gap-5 items-start">
          <div className="text-2xl md:text-3xl">{icon}</div>
          <h2 className="font-medium text-base sm:text-lg 2xl:text-xl mb-2">
            {title}
          </h2>
        </div>
        <p className="text-gray-500 text-xs sm:text-sm 2xl:text-lg">
          {description}
        </p>
        <Link
          to={url}
          className="absolute bottom-10 w-full left-0 text-white text-center"
        >
          <button
            className={`bg-green-600 border-green p-3 rounded-md transition-all ease-in-out duration-300 hover:bg-opacity-90 hover:shadow-xl text-sm 2xl:text-lg ${
              length > 2 ? "w-[80%]" : "w-[87%]"
            }`}
          >
            Request Information
          </button>
        </Link>
      </div>
    </>
  );
};

export default Products;
