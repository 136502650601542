import React from "react";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

const TestimonialCard = ({ message, client }) => {
  return (
    <div className="bg-gray-100 p-3 sm:p-16 lg:p-12 h-[350px] lg:h-[300px] rounded-3xl grid place-items-center">
      <ImQuotesLeft className="text-xl sm:text-4xl lg:text-6xl absolute left-3 top-3 sm:left-10 sm:top-10 text-gray-400" />
      <ImQuotesRight className="text-xl sm:text-4xl lg:text-6xl absolute right-3 bottom-3 sm:right-10 sm:bottom-10 text-gray-400" />
      <div className="grid gap-5 sm:gap-10 lg:gap-5 px-5 lg:px-40 justify-center items-center mx-auto sm:text-center my-5">
        <h3 className="text-base swiper-no-swiping sm:text-lg lg:text-xl font-light">{message}</h3>
        <p className="text-sm swiper-no-swiping sm:text-base lg:text-lg font-semibold">- {client}</p>
      </div>
    </div>
  );
};

export default TestimonialCard;
