import React, { useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import Hero from "../components/Hero";
// import AboutTeam from "../components/AboutTeam"

// Import Images
const aboutBg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/home/boston.webp";
const rapidPrototypingImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/about/rapid-prototyping.webp";
const reachMapImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/about/spinnaker-reach.webp";

const data = [
  {
    id: 1,
    title: "Get in touch",
    text: "Questions? Need a demo? Call or email us.",
    isIcon: false,
    urlContent: "Contact Us",
    url: "/contact",
  },
  {
    id: 2,
    title: "View our solutions",
    text: "Find the solutions that's right for you.",
    isIcon: false,
    urlContent: "Our Solutions",
    url: "/solutions/sales-prophet",
  },
  {
    id: 3,
    title: "Connect with us",
    text: "Follow us on LinkedIn and Twitter.",
    isIcon: true,
    icons: [
      {
        id: 1,
        icon: <FaLinkedin className="bg-text-twitter text-2xl lg:text-3xl text-linkedin" />,
        target: "_blank",
        url: "https://www.linkedin.com/company/spinnaker-analytics",
      },
      {
        id: 2,
        icon: <FaXTwitter className="bg-text-twitter text-2xl lg:text-3xl text-black" />,
        target: "_blank",
        url: "https://twitter.com/Spinnakerlytics",
      },
    ],
  },
];

const About = () => {
  const location = useLocation();
  const ourApproach = useRef(null);
  const ourClient = useRef(null);
  const ourTeam = useRef(null);
  const ourReach = useRef(null);

  useEffect(() => {
    if (window.location.hash === "#our-approach" && ourApproach.current) {
      ourApproach.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (window.location.hash === "#our-clients" && ourClient.current) {
      ourClient.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (window.location.hash === "#our-team" && ourTeam.current) {
      ourTeam.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (window.location.hash === "#our-reach" && ourReach.current) {
      ourReach.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [location]);

  return (
    <>
      <Hero
        image={aboutBg}
        title={"Headquartered in Boston, Expertise around the Globe"}
        description={
          "We combine decades of consulting and technical experience to deliver powerful solutions."
        }
      />

      <div className="container">
        <div className="flex items-center gap-2">
          {/* <FaUserAlt /> */}
          <h2 className="text-primary text-2xl lg:text-5xl font-semibold my-10">
            About Us
          </h2>
        </div>
        <hr />

        {/* History */}
        <div className="my-10">
          <h3 className="text-primary text-xl lg:text-3xl font-medium my-6">
            History
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-xl lg:leading-relaxed">
            Spinnaker Analytics was formed out of a professional services
            organization with decades of successful experience helping senior
            management at leading corporations improve strategic performance.
            Our combination of deep domain expertise and strong analytical
            capabilities paved the way for customizable products and solutions
            that yield rapid, actionable decisions across many facets of
            businesses.
          </p>
        </div>

        <hr />
        {/* Our Approach */}
        <div className="my-10">
          <h3
            className="text-primary text-xl lg:text-3xl font-medium my-6"
            ref={ourApproach}
          >
            Our Approach
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-xl lg:leading-relaxed">
            We build predictive models and analytical tools that combine
            historical business data, robust algorithms, and decades of industry
            and functional expertise to tackle business challenges effectively
            and undertake strategic initiatives profitably. While traditional
            solutions require significant upfront investment and create
            uncertainty in ROI and timing, Spinnaker Analytics employs a "rapid
            prototyping" approach using readily available data to deliver quick
            wins at a fraction of the cost. As a result, clients maximize their
            ROI and have the option of adding discretionary enhancements in the
            future.
          </p>
          <div className="my-10">
            <img loading="lazy"
              className="w-[90%]"
              src={rapidPrototypingImg}
              alt="Rapid Prototyping"
            />
          </div>
          <p className="font-light text-sm sm:text-base lg:text-xl lg:leading-relaxed">
            Our tools help leading insurers including Life, supplement, P&C
            identify high priority prospects, improve internal workflows
            efficiency and effectiveness, and optimize workforce allocation.
          </p>
        </div>

        <hr />

        {/* Our Client */}
        <div className="my-10">
          <h3
            className="text-primary text-xl lg:text-3xl font-medium my-6"
            ref={ourClient}
          >
            Our Clients
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-xl lg:leading-relaxed">
            We provide tools and solutions to asset management, insurance,
            private equity, banking, retail and various other Fortune 1000
            corporations. Our clients have used these tools to improve their
            performance in various ways, such as growing their sales,
            identifying new markets, and managing expenses. What business
            challenges and opportunities can Spinnaker Analytics solve for you?
          </p>
        </div>

        <hr />
        {/* Our Team */}
        <div className="my-10">
          <h3
            className="text-primary text-xl lg:text-3xl font-medium my-6"
            ref={ourTeam}
          >
            Our Team
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-xl lg:leading-relaxed my-4">
            The Spinnaker Analytics team is comprised of seasoned industry
            professionals with extensive experience in management solutions.
            This experience combined with analytically rigorous academics
            enables us to understand the intricacies of the challenges and
            opportunities facing complex environments.
          </p>

          {/* <AboutTeam /> */}
        </div>

        <hr />
        {/* Our Reach */}
        <div className="my-10">
          <h3
            className="text-primary text-xl lg:text-3xl font-medium my-6"
            ref={ourReach}
          >
            Our Reach
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-xl lg:leading-relaxed my-4">
            We've helped companies all over the world improve their performance.
            Our offices in 6 major cities across the globe enable us to serve
            clients on every continent. We have access to targets and
            resources/expertise in diverse geographies.
          </p>
        </div>

        <div className="my-20">
          <img loading="lazy" src={reachMapImg} alt="Spinnaker Reach" />
        </div>

        <div className="my-10 sm:my-20 grid sm:grid-cols-3 text-center">
          {data.map((item) => (
            <div
              key={item.id}
              className="flex flex-col justify-start items-center gap-2 border-t py-10 sm:px-2 lg:px-10 xl:px-5 sm:py-0 sm:border-x border-gray-200 sm:border-t-0"
            >
              <h4 className="font-medium text-primary lg:text-2xl">
                {item.title}
              </h4>
              <p className="text-sm lg:text-xl">{item.text}</p>
              <div className="my-4">
                {!item.isIcon ? (
                  <>
                    <Link
                      className={`rounded-lg text-white py-2 px-4 hover:shadow-xl hover:bg-opacity-90 transition ease-in duration-300 ${
                        item.urlContent === "Our Solutions"
                          ? "bg-green-600"
                          : "bg-tertiary"
                      }`}
                      to={item.url}
                    >
                      {item.urlContent}
                    </Link>
                  </>
                ) : (
                  <>
                    <div className="flex items-center gap-2">
                      {item.icons.map((i) => (
                        <Link key={i.id} to={i.url} target={i.target}>
                          {i.icon}
                        </Link>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default About;
