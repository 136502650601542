import React from "react";
import Card from "../components/Card";
import HomeCarousals from "../components/HomeCarousals";
import { CardData, WhyUsData } from "../data/homePageData";
import WhyUsCard from "../components/WhyUsCard";
import TestimonialCarousal from "../components/TestimonialCarousal";
import { Link } from "react-router-dom";
import HeroAnimate from "../components/HeroAnimate";

// Images import
const boston =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/home/boston.webp";

const Home = () => {
  return (
    <>
      {/* <video src={video} autoPlay loop muted playsInline width={"100%"} className="w-[100vw] object-fill h-full lg:h-[600px] 2xl:h-[850px] opacity-75 pt-12 lg:pt-20"></video> */}
      <HomeCarousals/>
      <HeroAnimate />

      <main className="container">
        {/* Who we are Section */}
        <div className="my-10 lg:my-20">
          <h2 className="text-primary capitalize text-center text-xl lg:text-3xl font-medium my-10">
            Who We Are
          </h2>
          <div className="lg:flex items-start gap-14 bg-cover">
            <img
              loading="lazy"
              src={boston}
              alt="boston"
              className="rounded-2xl pointer-events-none mb-5 lg:mb-0 mx-auto w-full lg:w-6/12 lg:h-[500px] "
            />
            <div className="h-full grid gap-3 rounded-r-2xl text-base lg:text-xl lg:leading-relaxed text-gray-800 font-light ">
              <p>
                Spinnaker Analytics is your key to unlocking the untapped
                potential within your technology and service assets. Our use of
                precision predictive models is informed by a balanced fusion of
                deep domain knowledge and the most advanced algorithms
                available.
              </p>
              <p>
                We specialize in developing custom analytics tools for industry
                leaders and small business owners alike. We bridge the realms of
                data science and practical business acumen to deliver advanced,
                yet intuitively accessible solutions that streamline
                decision-making and expedite results.
              </p>
              <p>
                With a wide-ranging portfolio, we offer custom products and
                solutions tailored to your unique operational and strategic
                needs. From optimizing sales strategies to identifying emerging
                market opportunities, budget planning to resource management,
                vendor governance, and more, Spinnaker Analytics is your ideal
                partner in shaping a data-driven future.
              </p>
              <div className="mt-5">
                <Link
                  to="about-us"
                  className="bg-tertiary px-8 py-2 rounded-md text-center text-base lg:text-lg text-white hover:bg-opacity-90 hover:shadow-xl transition ease-in duration-300"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* What we do Section */}
        <div className="my-20">
          <h2 className="text-primary capitalize text-center text-xl lg:text-3xl font-medium my-10">
            What We Do
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10">
            {CardData.map((item) => (
              <Card
                key={item.id}
                icon={item.icon}
                title={item.title}
                description={item.description}
                url={item.url}
              />
            ))}
          </div>
        </div>

        {/* Why Us Section */}
        <div className="my-20">
          <h2 className="text-primary capitalize text-center text-xl lg:text-3xl font-medium my-10">
            WHY US
          </h2>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-10">
            {WhyUsData.map((item) => (
              <WhyUsCard
                key={item.id}
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </div>

        {/* Testimonial Section */}
        <div>
          <h2 className="text-primary capitalize text-center text-xl lg:text-3xl font-medium my-10">
            WHAT OUR CUSTOMERS SAY
          </h2>
          <div className="my-10">
            <TestimonialCarousal />
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
