import React, { useEffect, useState } from "react";
import { homePageData } from "../data/carousel";
import { FaCircle, FaRegCircle } from "react-icons/fa6";

const HeroAnimate = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  let index = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (index < 4) {
        setActiveIndex(index + 1);
        index++;
      } else {
        setActiveIndex(0);
        index = 0;
      }
      console.log(index);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleBannerChange = (id) => {
    setActiveIndex(id);
  };

  return (
    <div className="h-screen max-h-[1100px] md:hidden flex justify-center items-center w-full overflow-hidden text-center bg-tertiary bg-opacity-50 relative px-10">
      {homePageData.map(
        (data, index) =>
          index === activeIndex && (
            <div
              key={index}
              className="md:w-3/4 max-w-[1300px] flex flex-col items-center gap-5"
            >
              <h1
                className="text-4xl lg:text-7xl leading-normal font-bold text-primary"
                data-aos="fade-up"
              >
                {data.title}
              </h1>
              <p
                className="text-2xl lg:text-4xl leading-relaxed"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                {data.description}
              </p>

              {/* Options to scroll between hero banners */}
              <div className="absolute bottom-14 left-[50%] md:bottom-[50%] md:left-[95%] translate-x-[-50%] md:translate-x-[50%] translate-y-[-50%] md:translate-y-[50%] flex md:flex-col gap-2 border-2 border-primary px-2 py-4 rounded-full">
                {homePageData.map((data, id) =>
                  id === activeIndex ? (
                    <FaCircle key={id} className="text-primary" />
                  ) : (
                    <FaRegCircle
                      key={id}
                      className="cursor-point text-primary"
                      onClick={() => {
                        handleBannerChange(id);
                      }}
                    />
                  )
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default HeroAnimate;
