import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import SubHero from "../components/SubHero";
import { blogContent, categories } from "../data/blog";
import BlogCarousal from "../components/BlogCarousal";

// Import Images
const insightsBg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/numbers-blue.webp";

const Insights = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [blogData, setBlogData] = useState(blogContent);
  const location = useLocation();
  const blogRef = useRef(null);

  const onTabChange = (category) => {
    setActiveTab(category);
    if (category === "All") {
      setBlogData(blogContent);
      return;
    }

    const data = [...blogContent];
    const filteredData = data.filter((item) => item.category === category);
    setBlogData(filteredData);
  };

  useEffect(() => {
    if (window.location.hash === "#blogs" && blogRef.current) {
      blogRef.current.scrollIntoView({
        block: "center",
      });
    }
  }, [location]);

  return (
    <>
      <SubHero image={insightsBg} title={"Spinnaker Insights"} />

      <div className="container">
        <div className="my-10 lg:my-20">
          {/* <h2 className="text-xl font-semibold text-primary mb-5">
            Spinnaker Insights
          </h2> */}
          <div className="grid lg:grid-cols-2 items-start justify-between gap-5 lg:gap-10 2xl:gap-16">
            <div className="order-last lg:-order-1 grid gap-5 font-light text-sm lg:text-lg">
              <h1 className="text-primary text-lg lg:text-3xl lg:leading-normal font-semibold mb-2">
                {blogContent[0].title}
              </h1>
              <div className="whitespace-pre-wrap">
                {blogContent[0].description}
              </div>
              <div className="my-2">
                <Link
                  to={blogContent[0].slug}
                  className="bg-tertiary px-8 py-2 rounded-md text-center text-base lg:text-lg text-white hover:bg-opacity-90 hover:shadow-xl transition ease-in duration-300"
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="">
              <img
                loading="lazy"
                src={blogContent[0].img[0]}
                alt={blogContent[0].title}
                className="rounded-2xl mx-auto"
              />
            </div>
          </div>
        </div>

        {/* Blog Section */}
        <div className="my-20" ref={blogRef}>
          {/* Category */}
          <div className="flex gap-2 items-center text-sm lg:text-lg lg:gap-5">
            {categories.map((item, index) => (
              <p
                key={index}
                className={
                  item === activeTab
                    ? "bg-primary text-white py-1 px-3 rounded-lg cursor-pointer"
                    : "bg-gray-200 cursor-pointer py-1 px-3 rounded-lg hover:bg-blue-100"
                }
                onClick={() => onTabChange(item)}
              >
                {item}
              </p>
            ))}
          </div>

          {/* Blog Card Section */}
          <div className="my-8">
            <BlogCarousal blogData={blogData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Insights;
