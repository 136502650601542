import { BsFillCreditCardFill } from "react-icons/bs";
import {
  FaDollarSign,
  FaShoppingCart,
  FaPaintBrush,
  FaDesktop
} from "react-icons/fa";
import { FiArrowDownCircle } from "react-icons/fi";
import { BiSolidLockAlt } from "react-icons/bi";
import { IoSpeedometer } from "react-icons/io5";
import { GiCheckedShield } from "react-icons/gi"

export const WhyUsData = [
  {
    id: 1,
    icon: <FaPaintBrush className="text-tertiary"/>,
    title: "Customizable",
    description:
      "Skip the cookie-cutter approach to software. We partner with our clients to ensure solutions are tailored to their unique specifications.",
  },
  {
    id: 2,
    icon: <IoSpeedometer className="text-tertiary"/>,
    title: "Rapid Prototyping",
    description:
      "We employ an iterative development approach to help clients realize quick wins sooner, even before the solution is deployed.",
  },
  {
    id: 3,
    icon: <FaDollarSign className="text-tertiary"/>,
    title: "High ROI",
    description:
      "Our solutions are cost-effective and deliver tremendous value to your organization with exponential return on investment.",
  },
  {
    id: 4,
    icon: <FaDesktop className="text-tertiary"/>,
    title: "Compelling Dashboards",
    description:
      "We deploy captivating visualizations on trusted BI platforms so you can get to insights and decisions sooner.",
  },
  {
    id: 5,
    icon: <FiArrowDownCircle className="text-tertiary"/>,
    title: "Ease of Implementation",
    description:
      "Run uninterrupted. We offer both SaaS and on premises deployments with minimal integration requirements.",
  },
  {
    id: 6,
    icon: <BiSolidLockAlt className="text-tertiary"/>,
    title: "Data Privacy",
    description:
      "We know your data is sensitive. Exchange and storage of data is backed by leading providers with state-of-the-art encryption.",
  },
];

export const CardData = [
  {
    id: 1,
    icon: <GiCheckedShield />,
    title: "Insurance",
    description:
      "Our tools help leading insurers identify high priority case prospects, improve their workflows, and optimize workforce allocation.",
    url: "./solutions/#insurance",
  },
  {
    id: 2,
    icon: <FaShoppingCart />,
    title: "E-Commerce & Retail",
    description:
      "Technological innovation is rapidly changing retail; leverage predictive analytics to make strategic decisions in a changing business landscape.",
    url: "./solutions/#ecommerce-and-retail",
  },
  {
    id: 3,
    icon: <BsFillCreditCardFill />,
    title: "Financial Services",
    description:
      "We help you identify market opportunities, intelligently assign financial targets, and monetize high-growth, profitable customer segments.",
    url: "./solutions/#financial-services",
  },
];

export const TestimonialData = [
  {
    id: 1,
    message: "The Sales Prophet™ is awesome – they knocked it out of the park!",
    client: "Head of Distribution, Life Insurer",
  },
  {
    id: 2,
    message: "When I want correct answers quickly, I call Spinnaker",
    client: "CEO, HNW brokerage",
  },
  {
    id: 3,
    message: "Their algorithms are worth their weight in gold",
    client: "CEO, Life Insurer",
  },
  {
    id: 4,
    message:
      "We grew sales 30% without adding any headcount because Spinnaker’s solution allowed us to work smarter",
    client: "Head of Employee Benefits Insurer",
  },
  {
    id: 5,
    message:
      "Spinnaker's product solutions have helped us transform our cross-department collaboration, freeing up senior managers to focus on our existing and evolving new customer base.",
    client: "SVP & COO, Operations & IT",
  },
  {
    id: 6,
    message:
      "The Channel Optimizer™ and Sales Prophet™ products have helped us approach the marketplace in a more efficient way which has led to a significant increase in sales and distribution effectiveness!",
    client: "Head of Distribution",
  },
];
