import React, { useEffect } from "react";
import Products from "../components/Products";
import { useState } from "react";
import NotFound from "./NotFound";
import { solutionContent } from "../data/solutions";
import { Link, useLocation, useParams } from "react-router-dom";
import SolutionSections from "../components/SolutionSections";

// Import Images
const solutionsBg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/solution/solution-background.webp";

const Solutions = () => {
  const { solutionSlug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [solutionData, setSolutionData] = useState(null);

  useEffect(() => {
    solutionContent.map((item) => {
      if (item.slug === solutionSlug) {
        setSolutionData(item);
        return setIsSlugCorrect(true);
      } else {
        return null;
      }
    });
  }, [solutionSlug]);

  const location = useLocation();

  useEffect(() => {
    if (solutionData) {
      solutionData?.content?.map((item) => {
        if (
          window.location.hash === "#" + item.url.slice(17) &&
          document.getElementById(item.url.slice(17))
        ) {
          document.getElementById(item.url.slice(17)).scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return null
      });
    }
  }, [solutionData, location]);

  return (
    <>
      {isSlugCorrect && solutionData ? (
        <>
          {/* Hero Content */}
          <div
            className="bg-cover bg-center bg-no-repeat h-[340px] md:h-[500px] lg:min-h-screen 2xl:min-h-[850px] relative"
            style={{
              backgroundImage: `url(${solutionsBg})`,
            }}
          >
            <div className="absolute bottom-0 flex gap-5 bg-black bg-opacity-60 p-10 w-fit">
              <div className="text-white mt-1 text-xl md:text-2xl lg:text-4xl 2xl:text-5xl">
                {solutionData.icon}
              </div>
              <h1 className="text-white font-light swiper-no-swiping text-xl md:text-2xl lg:text-4xl 2xl:text-5xl">
                {solutionData.title}
              </h1>
            </div>
          </div>
          {/* Page Content */}
          <div className="container">
            {solutionData.title === "Custom Solutions" ? (
              <div className="my-10 lg:my-20">
                <p className="font-light text-base lg:text-2xl my-10">
                  Looking for a different solution to help your organization? We
                  have an extensive portfolio of proprietary solutions and
                  toolkits across many general and niche initiatives, including
                  call center optimization, service level tracking, strategic
                  sourcing, etc. Whatever your business priorities are, contact
                  Spinnaker Analytics today to find the solution that will fit
                  your needs.
                </p>
                <Link
                  to="/contact?product=custom-solutions "
                  className="w-full left-0 text-white text-center"
                >
                  <button className="bg-green-600 border-green p-3 rounded-md text-sm 2xl:text-lg">
                    Request Information
                  </button>
                </Link>
              </div>
            ) : (
              <div className="my-10 lg:my-20">
                {/* Descripton */}
                <p className="font-light text-sm md:text-lg lg:text-2xl lg:leading-relaxed">
                  {solutionData.description}
                </p>
                {/* Inner Content */}
                <div className="my-10 lg:my-5">
                  <SolutionSections
                    description={solutionData.description}
                    listItems={solutionData.listItems}
                    iframe={solutionData.iframe}
                    isIframe={solutionData.isIframe}
                    chart={solutionData.chart}
                  />
                </div>
                {/* Products / Deployments */}
                <div
                  className={`mb-10 lg:mb-20 grid sm:grid-cols-2 ${
                    solutionData.content.length > 2
                      ? "lg:grid-cols-3"
                      : "lg:grid-cols-2 lg:px-10 xl:px-20"
                  }  items-start gap-10`}
                >
                  {solutionData.content.slice(0, 4).map((item) => (
                    <Products
                      key={item.id}
                      icon={item.icon}
                      title={item.title}
                      description={item.description}
                      url={item.url}
                      length={solutionData.content.length}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <NotFound />
        </>
      )}
    </>
  );
};

export default Solutions;
