import React from "react";
import SubHero from "../components/SubHero";
const policy = "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/miscellaneous/privacypolicy.webp";

const PrivacyPolicy = () => {
  return (
    <>
      <SubHero image={policy} title={"Privacy Policy"} />
      <div className="container lg:w-9/12 2xl:w-7/12">
        {/* General */}
        <div className="my-10 grid gap-5 font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed">
          <p>
            This Privacy Policy applies to the websites, web portals, and mobile
            applications (together, the "Sites") provided by Spinnaker Analytics
            LLC, a company headquartered in Boston, Massachusetts, USA, and/or
            the other companies of the Spinnaker Group ("Spinnaker", “we”, “us”)
            who act as data controllers with respect to the personal data you
            share with Spinnaker under this Policy.
          </p>
          <p>
            Spinnaker knows that your privacy is important to you and takes
            privacy very seriously. We are committed to protecting your personal
            data and information in accordance with this Privacy Policy.
          </p>
          <p>
            By using the Sites, you consent to the collection, use, and storage
            of your personal data by us in the manner described in this Privacy
            Policy and elsewhere on the Sites. We reserve the right to amend
            this Privacy Policy from time to time. We will alert you to any such
            changes by updating this Privacy Policy. If we make material changes
            to this Privacy Policy that increase our rights to use personal data
            that we have previously collected about you, we will obtain your
            consent either through an email to your registered email address or
            by prominently posting information about the changes on our Sites.
          </p>
        </div>

        <hr />
        {/* Information we collect and how we use it */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Information we collect and how we use it
          </h3>
          <div className="grid gap-5 font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            <p>
              Spinnaker collects personal data from you when you request
              information from Spinnaker or supply information through the
              Sites, such as when you fill out a solution inquiry, request a
              demonstration, opt in to receive emails from Spinnaker, agree to
              participate in surveys, or apply for a role at Spinnaker. We may
              also collect personal data while conducting business activities.
            </p>
            <p>
              Personal data we collect might include your name, e-mail address,
              title, occupation, company or university affiliation, industry,
              region/country, relationship to Spinnaker, reason for contacting
              Spinnaker, topics you are interested in, any other background
              information provided with an application and any other information
              contained in messages you send us. Please do not send us any
              information you do not wish Spinnaker to use.
            </p>
            <p>
              You do not have to provide us with any personal data. However, if
              you do not, we may be unable to complete your request or to
              provide you with the services you are requesting.
            </p>
            <p>
              Spinnaker will keep your personal data for as long as needed to
              fulfill your request or for as long as we have a relationship with
              you. If you have applied for a role with Spinnaker, Spinnaker will
              keep your personal data associated with your application for a
              period of up to five years.
            </p>
            <p>
              We may use cookies, web beacons, pixels, and log files to obtain
              certain types of information when you browse our Sites. These are
              files placed on your computer or our server to store information
              including visitor preferences, most visited pages, or where
              visitors are located. Other information collected includes
              internet protocol (IP) addresses, browser type, Internet Service
              Provider (ISP), date and time stamp, referring/exit pages, and the
              number of clicks. Spinnaker uses this information to analyze
              trends, administer the site, optimize content, and understand
              visitor demographics. Such information is not personally
              identifiable.
            </p>
          </div>
        </div>

        <hr />
        {/* Third parties */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Third parties
          </h3>
          <div className="grid gap-5 font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            <p>
              Spinnaker will only disclose information to third parties under
              the following circumstances:
            </p>
            <ul className="list-disc grid gap-3 ml-5">
              <li>When explicitly requested by a visitor</li>
              <li>
                As required by law through subpoena, search warrant or other
                legal process
              </li>
            </ul>
            <p>
              Spinnaker otherwise does not collect, compile, disseminate, or
              sell personal identifying information to outside parties for
              marketing purposes.
            </p>
            <p>
              Third-party ad servers or ad networks use technologies like
              cookies, JavaScript, or web beacons in their respective
              advertisements and links, which may appear on our Sites. They
              automatically receive your IP address when this occurs. These
              technologies measure the effectiveness of their advertising
              campaigns and/or to personalize the advertising content that you
              see on websites that you visit. Spinnaker has no access to or
              control over these cookies used by third-party advertisers.
            </p>
          </div>
        </div>

        <hr />
        {/* Links to other websites */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Links to other websites
          </h3>
          <div className="grid gap-5 font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            <p>
              Spinnaker may provide links and embed content from third-party
              websites or information as a service to our visitors. If you use
              these links or embedded content, you will leave the Sites. Such
              links do not constitute or imply an endorsement, sponsorship, or
              recommendation by Spinnaker of the third party, the third-party
              website, or the information contained therein, and Spinnaker shall
              not be responsible or liable for your use thereof. Such use shall
              be subject to the terms of use and privacy policies applicable to
              those sites.
            </p>
          </div>
        </div>

        <hr />
        {/* Data security and retention */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Data security and retention
          </h3>
          <div className="grid gap-5 font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            <p>
              Spinnaker has implemented generally accepted standards of
              technology and operational security to protect personal data from
              loss, misuse, alteration, or destruction. Only authorized
              Spinnaker personnel can access personal data and must treat this
              information confidentially. Despite these precautions however,
              Spinnaker cannot guarantee that unauthorized persons will not
              obtain access to your personal data.
            </p>
            <p>
              Spinnaker retains personal data, as necessary, for the duration of
              the relevant business relationship, or until a visitor request
              that we delete that information. We retain mailing list
              information and emails for the period considered reasonable to
              facilitate the visitor’s requests.
            </p>
            <p>
              Should visitors subsequently choose to unsubscribe from mailing
              lists or user registrations, we will provide instructions, on the
              appropriate website area or in communications to our visitors.
            </p>
            <p>
              Spinnaker does not assume responsibility for verifying the ongoing
              accuracy of the content of personal information. When possible,
              Spinnaker will make appropriate corrections based on the updated
              information provided by the visitor.
            </p>
          </div>
        </div>

        <hr />
        {/* Your rights */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Your rights
          </h3>
          <div className="grid gap-5 font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            <p>
              Depending on where you reside, you may have certain rights
              regarding the personal data Spinnaker holds about you. For
              instance, you may have the right to request access to your
              information, obtain a copy of it as well as information about how
              we use it. You have the right to request that we correct
              incorrect, inaccurate, or out-of-date information about you. You
              may also have the right to request that we stop processing your
              information and/or withdraw your consent to such processing and to
              ask us to delete your information.
            </p>
            <p>
              If Spinnaker holds personal data about you, and you no longer wish
              for Spinnaker to process your data as described in this Policy, or
              if you wish to access, rectify, cancel, or oppose such processing,
              please contact us and we will put forth reasonable effort to
              delete your information.
            </p>
            <p>
              If you receive marketing communications from Spinnaker, you can
              opt out of receiving future emails from Spinnaker or unsubscribe
              from participating in our programs.
            </p>
            <p>
              If you think we are managing your information unlawfully, we
              encourage you to contact us. You may also have the right to lodge
              a complaint with your national supervisory authority.
            </p>
            <p>
              Please note that we will take reasonable steps to check your
              identity before completing your request.
            </p>
          </div>
        </div>

        <hr />
        {/* Children */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Children
          </h3>
          <div className="grid gap-5 font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            <p>
              The Sites covered by this Privacy Policy are not designed for or
              directed at children 16 years of age or younger. Spinnaker does
              not intentionally collect information about anyone under the age
              of 16 and requests that visitors do not submit such information to
              us.
            </p>
          </div>
        </div>

        <hr />
        {/* Contact us */}
        <div className="my-10 mb-32">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Contact us
          </h3>
          <div className="grid gap-5 font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            <p>
              If you have any questions about our Privacy Policy or would like
              to make a request regarding your personal data, please contact us
              at:
            </p>
            <div className="grid sm:grid-cols-2 items-end">
              <div>
                <p>Spinnaker Analytics, LLC</p>
                <p>131 Dartmouth St</p>
                <p>Boston, MA 02116</p>
              </div>
              <div>
                <a href="tel:+1 617-303-1937" className="text-tertiary">
                  <p>+1 617-303-1937</p>
                </a>
                <a
                  href="mailto:info@spinnakeranalytics.com"
                  className="text-tertiary"
                >
                  <p>info@spinnakeranalytics.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
