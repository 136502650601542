export const carouselData = [
  {
    id: 1,
    title: "Predictive tools and solutions to engineer strategic growth.",
    img: "",
    url: "",
  },
  {
    id: 2,
    title: "Unlock Hidden Value",
    img: "",
    url: "",
    description: `
        Data is the new soil. But most insights can be unlocked by using internal data from within the organization, without spending big bucks on “big data”
        `,
  },
  {
    id: 3,
    title: "Maximize Resource Leverage",
    img: "",
    url: "",
    description: `
        Your employees are your most precious asset. Focusing them where it matters will better position your organization to adapt to changing market conditions
        `,
  },
  {
    id: 4,
    title: "Capture Market Leadership",
    img: "",
    url: "",
    description: `
        Timely identification of emerging opportunities and strategic decision-making will propel your organization to significant and defensible competitive advantage
        `,
  },
];

export const homePageData = [
  {
    title: "Don't Panic, It's Easy!",
    images: [],
    description: (
      <span className="swiper-no-swiping">
        Blend your management{" "}
        <span className="text-tertiary font-semibold">art</span> with our data{" "}
        <span className="text-tertiary font-semibold">science</span> to create
        the perfect combination
      </span>
    ),
  },
  {
    title: "Messy Data? No Problem!",
    images: [],
    description: (
      <span className="swiper-no-swiping">
        Use Spinnaker to extract{" "}
        <span className="text-tertiary font-semibold">value</span> from
        incomplete data to put your business on path to profitable growth
      </span>
    ),
  },
  {
    title: "Unlock Hidden Value",
    images: [],
    description: (
      <span className="swiper-no-swiping">
        Data is the new{" "}
        <span className="text-tertiary font-semibold underline underline-offset-2">
          S
        </span>
        oil. We can help you unlock the insights.
      </span>
    ),
  },
  {
    title: "Capture Market Leadership",
    images: [],
    description: (
      <span className="swiper-no-swiping">
        Timely identification of emerging{" "}
        <span className="text-tertiary font-semibold">opportunities</span> and
        strategic decision-making will propel your organization to significant
        and defensible competitive{" "}
        <span className="text-tertiary font-semibold">advantage</span>
      </span>
    ),
  },
  {
    title: "No matter your size or data maturity",
    images: [],
    description: (
      <span className="swiper-no-swiping">
        Use Spinnaker to propel yourself and break away from the pack
      </span>
    ),
  },
];
