import axios from "axios";
import React, { useState } from "react";
import { PhoneInput } from "react-contact-number-input";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LuLoader2 } from "react-icons/lu";

// Convert query to product name for auto select
const queryToProductName = {
  "market-opportunity-generator": "Market Opportunity Generator",
  "customer-profiler": "Customer Profiler",
  "underwriting-engine": "Underwriting Engine",
  "commission-optimizer": "Commission Optimizer",
  "fraud-detection": "Fraud Detection",
  "sales-forecast": "Sales Forecast",
  "staffing-model": "Staffing Model",
  "app-prioritizer": "App Prioritizer",
  "territory-design": "Territory Design",
  "renewals-analytics": "Renewals Analytics",
  "scenario-planner": "Scenario Planner",
  "intelligent-goal-setter": "Intelligent Goal Setter",
  "return-propensity": "Return Propensity",
  "product-recommendation": "Product Recommendation",
  "route-optimization": "Route Optimization",
  "unit-cost-forecasting": "Unit Cost Forecasting",
  "productivity-assessment": "Productivity Assessment",
  "resource-navigator": "Resource Navigator",
  "reinsurance-pricing": "Reinsurance Pricing",
  "return-propensity-score": "Return Propensity Score",
  "store-site-identification": "Store Site Identification",
  "loyalty-program-analytics": "Loyalty Program Analytics",
  "optimize-pricing-strategy": "Optimize Pricing Strategy",
  "custom-solutions": "Custom Solutions",
};

// Select Options
const options = [
  { value: "App Prioritizer", label: "App Prioritizer" },
  { value: "Commission Optimizer", label: "Commission Optimizer" },
  { value: "Customer Profiler", label: "Customer Profiler" },
  { value: "Fraud Detection", label: "Fraud Detection" },
  { value: "Intelligent Goal Setter", label: "Intelligent Goal Setter" },
  { value: "Loyalty Program Analytics", label: "Loyalty Program Analytics" },
  {
    value: "Market Opportunity Generator",
    label: "Market Opportunity Generator",
  },
  { value: "Optimize Pricing Strategy", label: "Optimize Pricing Strategy" },
  { value: "Product Recommendation", label: "Product Recommendation" },
  { value: "Productivity Assessment", label: "Productivity Assessment" },
  { value: "Reinsurance Pricing", label: "Reinsurance Pricing" },
  { value: "Renewals Analytics", label: "Renewals Analytics" },
  { value: "Resource Navigator", label: "Resource Navigator" },
  { value: "Route Optimization", label: "Route Optimization" },
  { value: "Return Propensity", label: "Return Propensity" },
  { value: "Return Propensity Score", label: "Return Propensity Score" },
  { value: "Sales Forecast", label: "Sales Forecast" },
  { value: "Scenario Planner", label: "Scenario Planner" },
  { value: "Staffing Model", label: "Staffing Model" },
  { value: "Store Site Identification", label: "Store Site Identification" },
  { value: "Territory Design", label: "Territory Design" },
  { value: "Underwriting Engine", label: "Underwriting Engine" },
  { value: "Unit Cost Forecasting", label: "Unit Cost Forecasting" },
  { value: "Custom Solutions", label: "Custom Solutions" },
];

const EmailForm = () => {
  const [productQuery] = useSearchParams();
  const animatedComponents = makeAnimated();
  const initialState = {
    name: "",
    company: "",
    email: "",
    phoneNumber: "",
    product:
      productQuery.size > 0
        ? queryToProductName[productQuery.get("product")]
        : "",
    message: "",
    country: "",
  };
  const [emailFieldData, setEmailFieldData] = useState(initialState);
  const [load, setLoad] = useState(false);

  const handleOnChange = (e) => {
    setEmailFieldData({ ...emailFieldData, [e.target.name]: e.target.value });
  };

  const onPhoneChange = (e) => {
    setEmailFieldData({
      ...emailFieldData,
      phoneNumber: `${e?.countryCode} ${e?.phoneNumber}`,
      country: e?.countryData?.name,
    });
  };

  const onSelectInputChange = (e) => {
    setEmailFieldData({
      ...emailFieldData,
      product: e?.map((item) => item.value),
    });
  };

  function isValidEmail(email) {
    // Regular expression for basic email validation
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // Sending Email Not Completed Yet
  const onSendEmail = (e) => {
    e.preventDefault();
    setLoad(true);

    if (
      emailFieldData.company &&
      emailFieldData.email &&
      emailFieldData.name &&
      emailFieldData.phoneNumber &&
      emailFieldData.product
    ) {
      if (!isValidEmail(emailFieldData.email)) {
        toast.error("Please enter a valid email address", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      axios
        .post(
          "https://spinnaker-online-backend.azurewebsites.net/api/SpinnakerAnalytics/contactForm",
          // "http://localhost:5000/api/SpinnakerAnalytics/contactForm",
          {
            name: emailFieldData.name,
            email: emailFieldData.email,
            company: emailFieldData.company,
            phoneNumber: emailFieldData.phoneNumber,
            country: emailFieldData.country,
            product: emailFieldData.product.join(", "),
            message:
              emailFieldData.message === ""
                ? "No message"
                : emailFieldData.message,
          }
        )
        .then((res) => {
          // console.log(res.data);
          setTimeout(() => {
            setEmailFieldData(initialState);
            setLoad(false);
            toast.success("Message sent successfully", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }, 1000);
        })
        .catch((err) => {
          // console.log(err);
          setLoad(false);
          toast.error("Some error occured please try again later", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      setLoad(false);
      toast.error("All * fields are mandatory", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <form action="" method="post">
        <div className="sm:border rounded-lg sm:p-10 grid gap-5">
          <div className="grid gap-5 sm:flex sm:gap-10">
            {/* Name */}
            <div className="sm:w-1/2">
              <label htmlFor="name" className="font-bold text-primary">
                Name <sup className="text-red-500">*</sup>
              </label>
              <input
                required
                id="name"
                name="name"
                type="text"
                placeholder="Enter Your Name"
                value={emailFieldData.name || ""}
                onChange={(e) => handleOnChange(e)}
                className="p-4 border rounded-[0.2rem] w-full"
              />
            </div>
            {/* Company */}
            <div className="sm:w-1/2">
              <label htmlFor="Company" className="font-bold text-primary">
                Company <sup className="text-red-500">*</sup>
              </label>
              <input
                required
                id="Company"
                type="text"
                placeholder="Enter Company Name"
                name="company"
                value={emailFieldData.company || ""}
                onChange={(e) => handleOnChange(e)}
                className="p-4 border rounded-[0.2rem] w-full"
              />
            </div>
          </div>
          <div className="grid gap-5 sm:flex sm:gap-10">
            <div className="sm:w-1/2">
              <label htmlFor="email" className="font-bold text-primary">
                Email Address <sup className="text-red-500">*</sup>
              </label>
              <input
                required
                id="email"
                type="email"
                placeholder="Enter Email"
                name="email"
                value={emailFieldData.email || ""}
                onChange={(e) => handleOnChange(e)}
                className="p-4 border rounded-[0.2rem] w-full"
              />
            </div>
            <div className="sm:w-1/2">
              <label htmlFor="phoneNumber" className="font-bold text-primary">
                Phone Number <sup className="text-red-500">*</sup>
              </label>
              <PhoneInput
                required
                onChange={(e) => onPhoneChange(e)}
                placeholder={"Enter Phone Number"}
                className="p-4 border rounded-[0.2rem] w-full"
              />
              <input
                type="hidden"
                name="phone_number"
                value={emailFieldData.phoneNumber || ""}
              />
              <input
                type="hidden"
                name="country"
                value={emailFieldData.country || ""}
              />
            </div>
          </div>
          <div>
            <label htmlFor="product" className="font-bold text-primary">
              Product of Interest <sup className="text-red-500">*</sup>
            </label>
            <Select
              closeMenuOnSelect={false}
              classNamePrefix="react-select"
              components={animatedComponents}
              isMulti
              options={options}
              isSearchable={true}
              name="product"
              // onChange={handleOnChange}
              // value={options[1]}
              defaultValue={
                options[
                  options.findIndex(
                    (option) =>
                      option.value ===
                      queryToProductName[productQuery.get("product")]
                  )
                ]
              }
              onChange={(e) => onSelectInputChange(e)}
              // className="p-4 border rounded-[0.2rem] w-full"
            />
          </div>
          <div>
            <label htmlFor="message" className="font-bold text-primary">
              Message
            </label>
            <textarea
              value={emailFieldData.message || ""}
              onChange={(e) => handleOnChange(e)}
              name="message"
              id="message"
              className="p-4 border rounded-[0.2rem] w-full"
              placeholder="Write a message.."
            ></textarea>
          </div>
          {load ? (
            <div className="bg-gray-200 text-black p-2 rounded-[0.2rem] shadow-sm flex items-center justify-center gap-2">
              <span className="animate-spin">
                <LuLoader2 />
              </span>
              <span>Sending...</span>
            </div>
          ) : (
            <button
              type="submit"
              onClick={(e) => onSendEmail(e)}
              className="bg-tertiary text-white p-2 rounded-[0.2rem] hover:shadow-lg hover:bg-opacity-90"
            >
              Submit
            </button>
          )}
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default EmailForm;
