const USAImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/contact/usa-office.webp";
const IndiaImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/contact/india-office.webp";
const UKImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/contact/uk-office.webp";
const UAEImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/contact/uae-office.webp";

export const contactData = [
  {
    id: 1,
    country: "United States",
    address: "One Boston Place, 32nd Floor, Boston MA 02108",
    phoneNumber: "+1 617 303 1938",
    officeImg: USAImg,
    officeImgAlt: "USA Flag",
  },
  {
    id: 2,
    country: "India",
    address: "Kanakia Wallstreet, B-812A Andheri (E), Mumbai 400093",
    phoneNumber: "+91 22 6239 6057",
    officeImg: IndiaImg,
    officeImgAlt: "India Flag",
  },
  {
    id: 3,
    country: "United Kingdom",
    address: "30 St Mary Axe, 28F London, EC3A 8BF",
    phoneNumber: "+44 1865 60 0316",
    officeImg: UKImg,
    officeImgAlt: "UK Flag",
  },
  {
    id: 4,
    country: "UAE",
    address: "StanChart Tower, 5F Emaar Square, Dubai",
    phoneNumber: "+1 617 303 1938",
    officeImg: UAEImg,
    officeImgAlt: "UAE Flag",
  },
];
