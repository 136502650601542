import Forecast from "../charts/Forecast";
import CapMod from "../charts/CapMod";
import Returnify from "../charts/Returnify";
import { FaChalkboardTeacher, FaPaintBrush } from "react-icons/fa";
import {
  FaEarthAmericas,
  FaPeopleGroup,
  FaMapLocationDot,
  FaFileInvoiceDollar,
  FaRegChartBar,
} from "react-icons/fa6";
import { IoBarChart } from "react-icons/io5";
import { MdCurrencyExchange } from "react-icons/md";
import { GiPriceTag, GiStairsGoal } from "react-icons/gi";
import { BsPersonBoundingBox } from "react-icons/bs";
import { PiDetectiveFill } from "react-icons/pi";
import { GrDirections, GrOptimize } from "react-icons/gr";
import { ImLocation2 } from "react-icons/im";
import { BsStars } from "react-icons/bs";

// Images and Video Imports
const cartalyze =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/videos/cartayze-chart.mp4";
const cartalyzePoster =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/videos/posters/cartayze-chart.webp";

export const solutionContent = [
  {
    id: 1,
    title: "Sales Prophet™",
    listItems: [
      "Estimate granular market size",
      "Assess wallet share",
      "Design sales territory",
    ],
    icon: <FaEarthAmericas />,
    slug: "sales-prophet",
    isIframe: true,
    description:
      "Sales Prophet™ is a groundbreaking solution for market analysis and product sales forecasting. Accurately estimate market size, identify client-specific sales opportunities, and strategically allocate sales goals across regions, products, and customer segments. Sales Prophet™ uses top of the line, responsible AI techniques to aid with territory design and unlock your sales team's full potential, ensuring you target the right markets.",
    iframe: (
      <iframe
        className="h-44 sm:h-60 w-full lg:h-80 iframe"
        src="https://www.youtube.com/embed/GiunKXmcwIE"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allowFullScreen"
      ></iframe>
    ),
    content: [
      {
        id: 1,
        title: "Market Opportunity Generator",
        icon: <FaEarthAmericas />,
        description:
          "Estimate granular market size and analyze client performance to develop sales potential at product, demographic, and zip-code levels",
        url: "/contact?product=market-opportunity-generator",
      },
      {
        id: 2,
        title: "Intelligent Goal Setter",
        icon: <GiStairsGoal />,
        description: (
          <span className="lg:mt-7 inline-block">
            Automatically set and assign sales goals and expense allowance by
            territory, product, and customer / size segments
          </span>
        ),
        url: "/contact?product=intelligent-goal-setter",
      },
      {
        id: 3,
        title: "Territory Design",
        icon: <FaMapLocationDot />,
        description: (
          <span className="lg:mt-7 inline-block">
            Development of sales territories, sales organization structure cost
            implications and staffing recommendations based on market
            opportunities and organization strategic goals
          </span>
        ),
        url: "/contact?product=territory-design",
      },
    ],
  },
  {
    id: 2,
    title: "Lead Prioritizer™",
    listItems: [
      "Identify high-value prospects",
      "Develop customer segmentation",
      "Improve marketing ROI",
    ],
    slug: "lead-prioritizer",
    isIframe: true,
    icon: <FaChalkboardTeacher />,
    description:
      "Lead Prioritizer™ harnesses the capabilities of advanced pattern recognition algorithms to strategically prioritize work volumes and identify top prospects. Achieve higher yield rates, build a streamlined workflow, and reduce unit costs. Step into the future of workflow process management with Lead Prioritizer™ and take your organization's productivity to new heights.",
    iframe: (
      <iframe
        className="h-44 sm:h-60 w-full lg:h-80 iframe"
        src="https://www.youtube.com/embed/hOwWm-EUeIQ?si=M0nA-EuEU0x6REWs"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; allowFullScreen"
        allowFullScreen
      ></iframe>
    ),
    content: [
      {
        id: 1,
        title: "App Prioritizer",
        icon: <FaChalkboardTeacher />,
        description:
          "Prioritize work volumes using pattern recognition algorithms to maximize yield rates, optimize workflow, and reduce unit costs",
        url: "/contact?product=app-prioritizer",
      },
      {
        id: 2,
        title: "Customer Profiler",
        icon: <BsPersonBoundingBox />,
        description:
          "Analyze customer behavior patterns, identify target segments, develop product recommendations, and pin-point retention drivers",
        url: "/contact?product=customer-profiler",
      },
      {
        id: 3,
        title: "Fraud Detection",
        icon: <PiDetectiveFill />,
        description:
          "Analyze historical data to identify the normal behavior of each customer, and compare it with external data to spot any anomalies. Set up automatic alerts that indicate the probability of fraud based on the detected activities.",
        url: "/contact?product=fraud-detection",
      },
    ],
  },
  {
    id: 3,
    title: "4Qast™",
    listItems: [
      "Forecast sales at granular level",
      "Quantify variance and drivers",
      "Develop scenario planning",
    ],
    slug: "4qast",
    chart: <Forecast />,
    isIframe: false,
    icon: <IoBarChart />,
    description:
      "4Qast™ is Spinnaker’s premier financial prediction tool. By leveraging top of the line ML and responsible AI techniques, we empower businesses to harness the power of their data. Observe historical patterns and emerging trends to predict sales, revenue, and volumes like never before. 4Qast is a game-changer for data-driven decision-making, helping companies stay ahead of the curve and make informed strategic choices.",
    content: [
      {
        id: 1,
        title: "Sales Forecast",
        icon: <IoBarChart />,
        description:
          "Accurately and dynamically forecasts sales and volumes by business segment, product line, geography, channel, and other key attributes",
        url: "/contact?product=sales-forecast",
      },
      {
        id: 2,
        title: "Unit Cost Forecasting",
        icon: <FaFileInvoiceDollar />,
        description:
          "Dynamically forecasts cost per contract from sales to service based on product, fixed, variable cost structure, currency exchange rate, etc.",
        url: "/contact?product=unit-cost-forecasting",
      },
    ],
  },
  {
    id: 4,
    title: "CapMod™",
    listItems: [
      "Determine staffing requirements",
      "Identify work drivers",
      "Incorporate seasonality, complexity and cross-training",
    ],
    slug: "capmod",
    chart: <CapMod />,
    isIframe: false,
    icon: <FaPeopleGroup />,
    description:
      "Discover the transformative capabilities of CapMod™, Spinnaker’s definitive solution for optimizing workforce management. CapMod™ allows for expertly aligning skillsets and establishing precise staffing requirements by function, all while using advanced machine learning to take factors like complexity, seasonality, and cross-training into account. Transform your workforce planning framework and achieve peak efficiency and productivity in your organization.",
    content: [
      {
        id: 1,
        title: "Staffing Model",
        description:
          "Optimally align skillsets and determine staffing requirements by function while adjusting for complexity, seasonality, cross-training",
        url: "/contact?product=staffing-model",
        icon: <FaPeopleGroup />,
      },
      {
        id: 2,
        title: "Productivity Assessment",
        description:
          "Develop cohorts analytics to identify productivity drivers and establish productivity targets",
        url: "/contact?product=productivity-assessment",
        icon: <FaRegChartBar />,
      },
      {
        id: 3,
        title: "Resource Navigator",
        description:
          "Analyze the actual cost to serve along the complete lifecycle of the product/commodity to identify cost and resource drivers at each stage of the product lifecycle",
        url: "/contact?product=resource-navigator",
        icon: <GrDirections />,
      },
    ],
  },
  {
    id: 5,
    title: "Returnify™",
    listItems: [
      "Predict product return propensity",
      "Develop omni channel opportunities",
      "Identify future store location and sales potential",
    ],
    slug: "returnify",
    chart: <Returnify />,
    isIframe: false,
    icon: <MdCurrencyExchange />,
    description:
      "Returnify™ is your business’s comprehensive solution for predictive analysis and optimization in retail. Its sophisticated algorithm incorporating responsible artificial intelligence excels in predicting product return propensity to fine-tune your marketing. Explore using Returnify™ to create omni-channel opportunities and identify the ideal locale for your next store.",
    content: [
      {
        id: 1,
        title: "Return Propensity Score",
        description: (
          <span className="lg:mt-7 2xl:mt-0 inline-block">
            Develop real-time transaction return probability based on customer
            behavior and transaction attributes
          </span>
        ),
        url: "/contact?product=return-propensity-score",
        icon: <MdCurrencyExchange />,
      },
      {
        id: 2,
        title: "Store Site Identification",
        description: (
          <span className="lg:mt-7 2xl:mt-0 inline-block">
            Identify new store locations and formats at zip-code level based on
            physical and digital customer behavior
          </span>
        ),
        url: "/contact?product=store-site-identification",
        icon: <ImLocation2 />,
      },
      {
        id: 3,
        title: "Loyalty Program Analytics",
        description:
          "Analyze buyer behavior patterns, identify target segments, develop product recommendations, and pin-point retention drivers",
        url: "/contact?product=loyalty-program-analytics",
        icon: <BsStars />,
      },
    ],
  },
  {
    id: 6,
    title: "Cartalyze™",
    listItems: [
      "Develop product recommendation engine",
      "Optimize pricing strategy",
    ],
    slug: "cartalyze",
    chart: (
      <video
        src={cartalyze}
        poster={cartalyzePoster}
        playsInline
        autoPlay
        muted
        className="video bg-white w-full"
      ></video>
    ),
    isIframe: false,
    icon: <GiPriceTag />,
    description:
      "Cartalyze™ is your new, all-in-one tool for e-commerce success. Obtain automatic product recommendations tailored to a variety of demographics and stages of life through our responsible AI solutions. Take advantage of Cartalyze™’s design targeted pricing actions and promotional campaigns to boost profitability and enhance marketing. It's your go-to for developing a product recommendation engine and fine-tuning your pricing strategy.",
    content: [
      {
        id: 1,
        title: "Product Recommendation",
        description:
          "Automatically recommend products and solutions tailored to individual life stage and priorities in alignment with demographic attributes",
        url: "/contact?product=product-recommendation",
        icon: <GiPriceTag />,
      },
      {
        id: 2,
        title: "Optimize Pricing Strategy",
        description:
          "Design targeted pricing actions and impactful promotional campaigns to increase profitiblity",
        url: "/contact?product=optimize-pricing-strategy",
        icon: <GrOptimize />,
      },
    ],
  },
  {
    id: 7,
    title: "Custom Solutions",
    slug: "custom-solutions",
    icon: <FaPaintBrush />,
  },
];
