import React from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import BlogCard from "./BlogCard";

const BlogCarousal = ({ blogData = [] }) => {
  const navigate = useNavigate();

  return (
    <Swiper
      navigation={true}
      modules={[Navigation]}
      spaceBetween={30}
      loop={false}
      slidesPerView={4}
      breakpoints={{
        1280: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        0: {
          slidesPerView: 1,
        },
      }}
    >
      {blogData.map((item, id) => (
        <SwiperSlide key={id}>
          <BlogCard
            id={id}
            category={item.category}
            title={item.title}
            blogImage={item.img[0]}
            blogAlt={""}
            description={item.description}
            navigate={navigate}
            slug={item.slug}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BlogCarousal;
