import React from "react";

const WhyUsCard = ({ icon, title, description }) => {
  return (
    <>
      <div className="flex flex-col gap-3 p-6 items-center justify-start rounded-3xl bg-gray-100 text-center shadow-md lg:h-72 2xl:h-64">
        <span className="text-2xl xl:text-4xl">{icon}</span>
        <h3 className="text-lg xl:text-xl font-semibold text-primary">{title}</h3>
        <p className="text-base xl:text-lg font-light text-left">{description}</p>
      </div>
    </>
  );
};

export default WhyUsCard;
