import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { blogContent } from "../data/blog";
import NotFound from "./NotFound";

import { BsArrowLeftCircleFill } from "react-icons/bs";

const Blog = () => {
  const { blogSlug } = useParams();

  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [blogData, setBlogData] = useState({});

  useEffect(() => {
    blogContent.map((item) => {
      if (item.slug === blogSlug) {
        setBlogData(item);
        return setIsSlugCorrect(true);
      } else {
        return null;
      }
    });
  }, [blogSlug]);

  return (
    <>
      {isSlugCorrect && blogData ? (
        <>
          <div className="pt-12 lg:pt-20">
            <img
              loading="lazy"
              src={blogData.img[0]}
              className="grid sm:hidden items-center justify-center w-full"
              alt=""
            />
            <img
              loading="lazy"
              src={blogData.img[1]}
              className="hidden sm:grid object-cover object-top items-center justify-center w-full sm:max-h-[200px] md:max-h-[300px] lg:max-h-[600px] 2xl:max-h-[700px]"
              alt=""
            />
          </div>

          <div className="container">
            <div className="my-10 grid gap-3">
              <Link
                to="/insights/#blogs"
                className="flex items-center gap-2 text-primary"
              >
                <BsArrowLeftCircleFill className="text-lg md:text-xl" />
                <p className="underline text-sm md:text-base">
                  Back to Insights
                </p>
              </Link>
              <p className="bg-primary absolute right-10 shadow-xl rounded-md uppercase text-sm font-bold text-white p-2 w-5/12 sm:w-2/12 lg:w-1/12 text-center">
                {blogData.category}
              </p>
              <h2 className="mt-5 text-primary text-xl lg:text-4xl font-medium">
                {blogData.title}
              </h2>
              <p className="text-base font-extrabold text-gray-800">
                - By Spinnaker Analytics
              </p>
            </div>
            {blogData.img.length > 2 ? (
              <>
                <div className="flex flex-col lg:flex-row gap-10 justify-between">
                  <div>
                    <img
                      className="rounded-lg float-right text-center w-fit"
                      loading="lazy"
                      src={blogData.img[2]}
                      alt={blogData.title}
                    />
                  </div>
                  <p className="text-justify mb-20 text-base flex flex-col gap-3 lg:text-xl font-light order-last lg:-order-1 lg:w-2/3">
                    {blogData.body}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="grid gap-5 mb-20 text-base lg:text-xl font-light">
                  {blogData.body}
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <NotFound />
        </>
      )}
    </>
  );
};

export default Blog;
