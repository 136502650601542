import React from "react";
import { Link } from "react-router-dom";

const Hero = ({ image = "", title = "", description = "", url = "" }) => {
  return (
    <>
      <div
        title={title}
        aria-label={title}
        style={{
          backgroundImage: `url(${image})`,
        }}
        className="bg-contain hidden md:flex sm:bg-cover bg-top sm:mt-0 sm:bg-center bg-no-repeat h-[470px] sm:h-[340px] md:h-[500px] lg:min-h-screen relative"
        // className="h-[470px] sm:h-[340px] md:h-[500px] lg:min-h-screen relative"
      >
        <img
          src={image}
          alt={title}
          className="h-[280px] -mt-2 sm:h-full pt-14 sm:pt-0 object-cover sm:object-cover w-full"
        />
        <div className="h-full sm:h-fit sm:hidden bottom-0 bg-gray-950 sm:bg-opacity-60 p-10 pb-14 w-full lg:w-4/6">
          {description && (
            <p className="text-white font-medium text-sm md:text-lg lg:text-xl xl:text-2xl lg:leading-normal">
              {description}
            </p>
          )}
          {url && <Link to={url}>Learn more</Link>}
        </div>
      </div>
    </>
  );
};

export default Hero;
