import React from 'react'
import { TestimonialData } from '../data/homePageData';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import TestimonialCard from './TestimonialCard';

const TestimonialCarousal = () => {
  return (
    <>
        <Swiper
        spaceBetween={50}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        modules={[Autoplay, Pagination, Navigation]}
        slidesPerView={1}
        speed={1000}
        slideToClickedSlide={true}
      >
        {TestimonialData.map((item) => (
            <SwiperSlide key={item.id}>
                <TestimonialCard message={item.message} client={item.client} />
            </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default TestimonialCarousal;