import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { PopupButton, PopupModal } from "react-calendly";
import { menu } from "../data/menu";
// Icons
import { FaPaintBrush } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";
import { FaChevronUp } from "react-icons/fa6";
import { RiMenu4Fill } from "react-icons/ri";

const logo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const Header = () => {
  const location = useLocation();
  const [subMenuTab, setSubMenuTab] = useState(null);
  const [changeSubmenu, setChangeSubmenu] = useState(0);
  const [showSubMenu, setShowSubMenu] = useState(0);
  const [bttOpen, setBttOpen] = useState(false);
  // const [botOpen, setBotOpen] = useState(false);
  const calendlyRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [showCalendlyModal, setShowCalendlyModal] = useState(false);

  useEffect(() => {
    if (JSON.parse(searchParams.get("requestDemo"))) {
      // calendlyRef.current.click()
      setShowCalendlyModal(true);
    }
  }, []);

  const getActiveTabOnRefresh = (url) => {
    const path = url.split("/");
    return path[1];
  };
  const [activeTab, setActiveTab] = useState(
    getActiveTabOnRefresh(location.pathname)
  );

  useEffect(() => {
    setActiveTab(getActiveTabOnRefresh(location.pathname));

    // A way to check the url to assign active tab
    if (
      location.pathname.split("/")[1] === "industries" &&
      location.pathname.split("/").length > 2
    ) {
      setSubMenuTab(location.pathname.split("/")[2]);
    } else if (
      location.pathname.split("/")[1] === "solutions" &&
      location.pathname.split("/").length > 2
    ) {
      setSubMenuTab(location.pathname.split("/")[2]);
    } else {
      setSubMenuTab(null);
    }
  }, [location]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [showSTT, setShowSTT] = useState(false);

  // mobile menu
  const toggleMenu = (event) => {
    // const element = event.currentTarget.id;
    setMenuOpen(!menuOpen);
  };

  // Scroll To Top button show condition
  const showScrollBtn = () => {
    if (window.scrollY > 600) {
      setShowSTT(true);
    } else {
      setShowSTT(false);
    }
  };

  // Scroll To Top smooth transition
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Scroll To Top button show on scroll
  useEffect(() => {
    window.addEventListener("scroll", showScrollBtn);
    return () => window.removeEventListener("scroll", showScrollBtn);
  }, []);

  return (
    <>
      {/* Desktop Menu */}
      <header className="fixed-menu z-40 bg-white border-gray-200 border-b-[0.5px] lg:py-4">
        <div className="container bg-white">
          <nav className="relative flex items-center justify-center">
            {/* Logo */}
            <div className="w-2/4 lg:w-fit">
              <Link
                to="/"
                className="cursor-pointer"
                onClick={() => {
                  if (menuOpen) {
                    toggleMenu();
                  }
                  // To scroll to top
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <img
                  loading="lazy"
                  src={logo}
                  alt="logo"
                  className="w-28 sm:w-36 lg:w-40 pointer-events-none"
                />
              </Link>
            </div>
            {/* Desktop Menu */}
            <div className="hidden lg:block relative ml-auto">
              <ul className="relative list-none lg:flex gap-2 xl:gap-x-4 items-center text-sm xl:text-base">
                {/* Main Nav Menu Loop */}
                {menu.map((item) => (
                  <li
                    key={item.id}
                    onMouseEnter={() => {
                      if (item?.submenu?.length > 0) {
                        setShowSubMenu(item.id);
                      }
                    }}
                    onMouseLeave={() => {
                      if (item?.submenu?.length > 0) {
                        setShowSubMenu(0);
                      }
                    }}
                  >
                    <Link
                      to={item.url}
                      onClick={() => {
                        setShowSubMenu(false);
                        // To scroll to top
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className={`lg:p-4 flex gap-2 items-center rounded-lg font-semibold text-primary hover:text-tertiary hover:bg-gray-100 cursor-pointer transition ease-in-out duration-150 ${
                        activeTab === item.url.split("/")[1]
                          ? "bg-gray-100"
                          : ""
                      } ${
                        item.id === showSubMenu
                          ? "lg:bg-gray-100 lg:text-tertiary"
                          : ""
                      }`}
                    >
                      {item.name}{" "}
                      {item.submenu && <AiFillCaretDown className="w-3" />}
                    </Link>
                    {/* Submenu */}
                    {item?.submenu && (
                      <div
                        className={`absolute z-10 top-12 mt-1 xl:mt-2 submenu shadow-lg rounded-b-lg bg-white ${
                          item.id === showSubMenu ? "active" : ""
                        }`}
                      >
                        <div
                          className={`grid gap-1 border-solid border-t-[1px] mt-4 pt-4 px-5 py-2 ${
                            item.submenu.length > 7 ? "lg:grid-cols-2" : ""
                          }`}
                        >
                          {/* Sub Menu Loop */}
                          {item.submenu.map((subitem) => (
                            <div
                              className="relative"
                              key={subitem.id}
                              onMouseEnter={() => {
                                if (item.name === "Solutions") {
                                  setChangeSubmenu(subitem.id);
                                }
                              }}
                              onMouseLeave={() => {
                                if (item.name === "Solutions") {
                                  setChangeSubmenu(0);
                                }
                              }}
                            >
                              <Link
                                to={subitem.url}
                                className="cursor-pointer text-gray-600"
                                onClick={() => {
                                  setShowSubMenu(0);
                                  setChangeSubmenu(0);
                                  if (subitem.name !== "About Us") {
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "smooth",
                                    });
                                  }
                                }}
                              >
                                <span
                                  className={`flex gap-2 p-2 hover:bg-gray-100 rounded-lg hover:text-tertiary items-start ${
                                    subMenuTab === subitem.url.split("/")[2]
                                      ? "lg:bg-gray-100"
                                      : ""
                                  } ${
                                    subitem.id === changeSubmenu
                                      ? "lg:bg-gray-100 lg:text-tertiary"
                                      : ""
                                  }`}
                                >
                                  {subitem.icon && (
                                    <span className="mt-1">{subitem.icon}</span>
                                  )}
                                  {subitem.name}
                                </span>
                              </Link>
                              {/* Sub-Submenu */}
                              {subitem.subsubmenu && (
                                <div
                                  className={`fixed z-10 ml-[160px] xl:ml-[178px] -mt-[52px] xl:-mt-14 shadows subsubmenu rounded-r-lg bg-white ${
                                    subitem.id === changeSubmenu ? "active" : ""
                                  }`}
                                >
                                  <div className="grid gap-1 px-5 py-5 min-w-max">
                                    {/* Sub-sub Menu Loop */}
                                    {subitem.subsubmenu.map((subsubitem) => (
                                      <div key={subsubitem.id}>
                                        <Link
                                          to={subsubitem.url}
                                          className="cursor-pointer text-gray-600"
                                          onClick={() => {
                                            setShowSubMenu(0);
                                            setChangeSubmenu(0);
                                          }}
                                        >
                                          <span className="flex gap-2 p-2 hover:bg-gray-100 rounded-lg hover:text-tertiary items-start">
                                            <span className="mt-1">
                                              {subsubitem.icon}
                                            </span>
                                            {subsubitem.name}
                                          </span>
                                        </Link>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        {item.name !== "Solutions" && (
                          <div className="my-2"></div>
                        )}
                        {item.name === "Solutions" && (
                          <div className="grid px-5 pb-3">
                            <hr className="mb-2" />
                            <Link
                              to="/solutions/custom-solutions"
                              className="cursor-pointer text-gray-600"
                              onClick={() => {
                                setShowSubMenu(0);
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              <span
                                className={`flex gap-2 p-2 hover:bg-gray-100 rounded-lg hover:text-tertiary items-start ${
                                  subMenuTab ===
                                  "/solutions/custom-solutions".split("/")[2]
                                    ? "lg:bg-gray-100"
                                    : ""
                                }`}
                              >
                                <span className="mt-1">
                                  <FaPaintBrush />
                                </span>
                                Custom Solutions
                              </span>
                            </Link>
                          </div>
                        )}
                      </div>
                    )}

                    {/* Sub-Submenu */}
                    {/* {item.name === "Solutions" &&
                      item.submenu.map((subitem) => (
                        <div
                          className={`absolute z-10 top-[] left-2/4 -mt-7 xl:mt-2 submenu shadow-lg rounded-b-lg bg-white ${
                            subitem.id === changeSubmenu ? "active" : ""
                          }`}
                        >
                          <div className="grid gap-1 border-solid border-t-[1px] mt-4 pt-4 px-5 py-2 min-w-max">
                            {subitem?.subsubmenu?.length > 0 &&
                              subitem.subsubmenu.map((subsubitem) => (
                                <div key={subsubitem.id}>
                                  <Link
                                    to={subsubitem.url}
                                    className="cursor-pointer text-gray-600"
                                    onClick={() => {
                                      setShowSubMenu(0);
                                    }}
                                  >
                                    <span className="flex gap-2 p-2 hover:bg-gray-100 rounded-lg hover:text-tertiary items-start justify-center">
                                      <span className="mt-1">
                                        {subsubitem.icon}
                                      </span>
                                      {subsubitem.name}
                                    </span>
                                  </Link>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))} */}
                  </li>
                ))}
                {/* Popup Button for request demo */}
                <li ref={calendlyRef}>
                  <PopupButton
                    url="https://calendly.com/spinnaker-analytics/30min?primary_color=f0ad4e"
                    rootElement={document.getElementById("root")}
                    text="Request Demo"
                    className="p-3 px-5 rounded-full font-semibold border-tertiary border text-white bg-tertiary hover:text-tertiary cursor-pointer transition duration-300 ease-in hover:bg-white hover:shadow-lg"
                  />
                  {showCalendlyModal && (
                    <PopupModal
                      url="https://calendly.com/spinnaker-analytics/30min?primary_color=f0ad4e"
                      onModalClose={() => setShowCalendlyModal(false)}
                      open={showCalendlyModal}
                      rootElement={document.getElementById("root")}
                    />
                  )}
                </li>
              </ul>
            </div>
            {/* Mobile Burger */}
            <div className="p-3 lg:hidden w-2/4 text-right">
              <button
                className="text-black text-2xl relative ml-auto mt-1"
                onClick={toggleMenu}
              >
                <RiMenu4Fill
                  className={`transition duration-500  ease-in-out ${
                    menuOpen ? "rotate-90" : ""
                  }`}
                />
              </button>
            </div>
          </nav>
        </div>
      </header>
      {/* Mobile Menu Opening */}
      <div
        className={classNames(
          "lg:hidden flex fixed z-30 top-[58px] left-0 h-full w-full ease-in transform duration-500 overflow-y-auto bg-black bg-opacity-30",
          {
            // "translate-y-0": menuOpen,
            "-translate-y-full": !menuOpen,
          }
        )}
      >
        <div className="bg-white h-fit sm:h-full overflow-y-auto w-full sm:w-4/6 relative p-8 mb-12">
          <ul className="grid gap-5 items-center">
            {/* Main Nav Menu Loop */}
            {menu.map((item) => (
              <li key={item.id}>
                <div className="flex gap-2 items-center">
                  <Link
                    to={item.url}
                    className={`font-semibold text-primary hover:text-tertiary cursor-pointer ${
                      activeTab === item.url.split("/")[1]
                        ? "text-tertiary"
                        : ""
                    }`}
                    onClick={() => {
                      setShowSubMenu(0);
                      setChangeSubmenu(0);
                      toggleMenu();
                      // To scroll to top
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    {item.name}{" "}
                  </Link>
                  {/* Down icon for submenu indication */}
                  {item.submenu && (
                    <AiFillCaretDown
                      className={`w-5 ml-3 cursor-pointer transition duration-500 ease-in-out ${
                        item.id === showSubMenu ? "rotate-180" : ""
                      }`}
                      onClick={() => {
                        if (item?.submenu?.length > 0) {
                          setShowSubMenu(item.id);
                        }
                        if (showSubMenu === item.id) {
                          setShowSubMenu(0);
                        }
                      }}
                    />
                  )}
                </div>
                {/* Submenu starts */}
                {item?.submenu && (
                  <div
                    className={`bg-white w-fit submenu 
                        ${item.id === showSubMenu ? "active" : ""}`}
                  >
                    <div className="p-4">
                      {/* Sub Menu Loop */}
                      {item.submenu.map((subitem) => (
                        <div key={subitem.id}>
                          <div className="flex gap-2">
                            <Link
                              to={subitem.url}
                              className={`hover:text-tertiary cursor-pointer font-medium text-gray-600 min-w-max ${
                                subMenuTab === subitem.url.split("/")[2]
                                  ? "text-tertiary"
                                  : ""
                              }`}
                              onClick={() => {
                                setShowSubMenu(0);
                                setChangeSubmenu(0);
                                toggleMenu();
                                // To scroll to top
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                            >
                              <span className="flex gap-2 items-start my-3">
                                <span className="mt-1">{subitem.icon}</span>
                                {subitem.name}
                              </span>
                            </Link>
                            {/* Down icon for sub-submenu indication */}
                            {subitem?.subsubmenu && (
                              <AiFillCaretDown
                                className={`w-5 ml-3 cursor-pointer transition duration-500 ease-in-out mt-4 ${
                                  subitem.id === changeSubmenu
                                    ? "rotate-180"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (subitem?.subsubmenu?.length > 0) {
                                    setChangeSubmenu(subitem.id);
                                  }
                                  if (changeSubmenu === subitem.id) {
                                    setChangeSubmenu(0);
                                  }
                                }}
                              />
                            )}
                          </div>
                          {/* Sub-submenu starts */}
                          {subitem?.subsubmenu && (
                            <div
                              className={`bg-white w-fit submenu  ${
                                subitem.id === changeSubmenu ? "active" : ""
                              }`}
                            >
                              <div className="px-4 pb-4">
                                {/* Sub-sub Menu Loop */}
                                {subitem.subsubmenu.map((subsubitem) => (
                                  <div key={subsubitem.id}>
                                    <Link
                                      to={subsubitem.url}
                                      className="hover:text-tertiary cursor-pointer font-medium text-gray-600 min-w-max"
                                      onClick={() => {
                                        setShowSubMenu(0);
                                        setChangeSubmenu(0);
                                        toggleMenu();
                                        // To scroll to top
                                        window.scrollTo({
                                          top: 0,
                                          behavior: "smooth",
                                        });
                                      }}
                                    >
                                      <span className="flex gap-2 items-start my-3">
                                        <span className="mt-1">
                                          {subsubitem.icon}
                                        </span>
                                        {subsubitem.name}
                                      </span>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      {item.name === "Solutions" && (
                        <Link
                          to="/solutions/custom-solutions"
                          className={`hover:text-tertiary cursor-pointer font-medium text-gray-600 ${
                            subMenuTab ===
                            "/solutions/custom-solutions".split("/")[2]
                              ? "text-tertiary"
                              : ""
                          }`}
                          onClick={() => {
                            setShowSubMenu(0);
                            toggleMenu();
                          }}
                        >
                          <span className="flex gap-2 items-start my-3">
                            <span className="mt-1">
                              <FaPaintBrush />
                            </span>
                            Custom Solutions
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </li>
            ))}
            <li ref={calendlyRef}>
              <PopupButton
                url="https://calendly.com/spinnaker-analytics/30min?primary_color=f0ad4e"
                rootElement={document.getElementById("root")}
                text="Request Demo"
                className="p-3 px-5 rounded-full font-semibold border-tertiary border text-white bg-tertiary hover:text-tertiary cursor-pointer transition duration-300 ease-in hover:bg-white hover:shadow-lg"
              />
              {showCalendlyModal && (
                <PopupModal
                  url="https://calendly.com/spinnaker-analytics/30min?primary_color=f0ad4e"
                  onModalClose={() => setShowCalendlyModal(false)}
                  open={showCalendlyModal}
                  rootElement={document.getElementById("root")}
                />
              )}
            </li>
          </ul>
        </div>
      </div>
      {/* Back To Top Button */}
      {showSTT && (
        <div className="fixed z-20 bottom-32 right-5 lg:right-0">
          <button
            className={`transition-[font-size] ease-in-out duration-300 hover:text-tertiary bg-gray-200 bg-opacity-40 p-2 flex gap-2 ${
              bttOpen ? "text-xs" : "text-[0px]"
            }`}
            onClick={() => {
              scrollToTop();
              setBttOpen(false);
            }}
            onMouseEnter={() => {
              setBttOpen(true);
            }}
            onMouseLeave={() => {
              setBttOpen(false);
            }}
          >
            {bttOpen && <p>Back to top</p>}
            <FaChevronUp className="text-xl" />
          </button>
        </div>
      )}
    </>
  );
};

export default Header;
