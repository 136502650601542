const eCommerceBrickNewImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/retail-strategy/ecommerce-bricknew.webp";
const eCommerceBrickNewImg2 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/retail-strategy/fresh-commerce.webp";
const aIRobotImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/ai-hallucinates/AIRobot.webp";
const aIRobotImg2 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/ai-hallucinates/robot.webp";
const targetCardImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/real-problem/target.webp";
const precisionaccuracyImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/real-problem/precisionaccuracy.webp";
const rocketImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/feynman/rocket.webp";
const feynmanImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/feynman/feynman.webp";
const clarityImg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/accuracy-precision/clarity.webp";
const clarityImg2 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/accuracy-precision/clarity-big.webp";
const oppositesAttract =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/opposites-attract/science-and-art.webp";
const hidingPlainSight_1 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/hiding-in-plain-sight.webp";
const fourier =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/fourier_pic.webp";
const puzzlePiece =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/perfect-data-not-needed/puzzle-piece.webp";
const intelligentWorkflow =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/insights/intelligent-workflow.jpg";

export const categories = ["All", "Tech", "Process", "Business"];

export const blogContent = [
  {
    title: "Intelligent workflow: New tools for working smarter, not harder",
    slug: "intelligent-workflow-new-tools-for-working-smarter-not-harder",
    category: "Business",
    img: [intelligentWorkflow, intelligentWorkflow],
    description:
      "Underwriters play a pivotal role in balancing risk management with effective customer acquisition – but they are a scarce resource. A new class of intelligent tools using data-driven insights is emerging to help underwriters prioritize case workflow and improve case placement.",
    body: (
      <>
        <p>
          Underwriters play a pivotal role in balancing risk management with
          effective customer acquisition – but they are a scarce resource. A new
          class of intelligent tools using data-driven insights is emerging to
          help underwriters prioritize case workflow and improve case placement.
        </p>
        <blockquote>
          Work smarter - Increase production and advisor satisfaction by using
          intelligent workflow to underwrite attractive apps.
        </blockquote>
        <p className="font-semibold">Current hindrances to placements</p>
        <ul className="flex flex-col gap-2 ml-10 list-decimal text-lg">
          <li>
            <span className="font-semibold">
              The first-in-first-out approach squanders
            </span>{" "}
            the underwriting effort on poor-quality apps, while attractive cases
            get overlooked and placed elsewhere.
          </li>
          <li>
            <span className="font-semibold">Squeaky wheels</span> get the oil
            with ad-hoc escalations from outside of underwriting, disrupting
            workflow and creating constant scrambles.
          </li>
          <li>
            <span className="font-semibold">Skillset mismatches</span> with
            higher pen or authority and senior underwriters often working on low
            value/flow apps and poor workload balance among the underwriting
            pool-driving attrition and lower morale.
          </li>
        </ul>
        <p className="font-semibold">
          Intelligent workflow to empower underwriters
        </p>
        <p>
          The right predictive tool is designed to support and enhance (not
          replace) the risk assessment expertise of underwriters. It provides
          underwriters with powerful data- driven analytics for:
        </p>
        <ul className="flex flex-col gap-2 ml-10 list-disc text-lg">
          <li>
            <span className="font-bold">Prioritizing workflows: </span> Using
            real-time data to help underwriters focus on cases that require
            their expert evaluation.
          </li>
          <li>
            <span className="font-bold">
              Distributing case load intelligently:{" "}
            </span>
            Maximizing underwriting talent and capacity by matching up apps with
            the appropriate underwriter to improve upon traditional assignments.
          </li>
          <li>
            <span className="font-bold">Streamlining data gathering: </span>{" "}
            Handling initial data aggregation and processing to dedicate
            underwriting time to risk assessment and decision-making.
          </li>
          <li>
            <span className="font-bold">Enriching advisor conversations: </span>{" "}
            The technology provides a lens into high propensity attributes to
            guide advisor conversations and increase production.
          </li>
        </ul>
        <p className="font-semibold">Who else should care?</p>
        <p>
          The benefits extend beyond underwriting to distribution, marketing,
          account management, and executive leadership. Carriers can put their
          best foot forward and monetize core competence and strategic
          differentiation through targeted engagement with advisors on apps,
          increasing wallet share while streamlining the 'window shopping'
          tendency.
        </p>
        <p>
          It fosters greater alignment between various departments to drive
          profitable growth. Leveraging intelligent workflow has demonstrated
          promising results in decision- making and production. For example,
          focusing on high-value cases has increased placement rates by 25% or
          more.
        </p>
        <p className="font-semibold">The proof is in the pudding</p>
        <p>
          A leading carrier implemented this type of technology and saw
          remarkable improvements:
        </p>
        <ul className="flex flex-col gap-2 ml-10 list-disc text-lg">
          <li>
            20% sales growth in the first year and 35% by the end of year two
            while keeping underwriting staffing steady and driving up placement
            rates.
          </li>
          <li>
            Improved underwriting morale, retention and productivity by removing
            administrative work and focusing scarce resources on technical
            assessment, including underwriting leadership team.
          </li>
          <li>
            Elevated advisor satisfaction and engagement through targeted
            conversations focused on app-attributes and alignment on the flow of
            attractive cases specific to each channel partner.
          </li>
        </ul>
        <p>
          Carriers and advisors alike benefit from using smart tools, which
          boost workflow and productivity and provide a competitive edge in the
          market to drive profitable growth. 58 Aspire May June 2024
        </p>
      </>
    ),
  },
  {
    title: "Perfect Data Not Needed!",
    slug: "perfect-data-not-needed",
    category: "Business",
    img: [puzzlePiece, puzzlePiece],
    description:
      "Use Spinnaker to extract value from incomplete data to put your business on path to profitable growth",
    body: (
      <>
        <p>
          Use Spinnaker to extract value from incomplete data to put your
          business on path to profitable growth.
        </p>
        <p>
          Businesses are struggling with vast amounts of data, much of it seen
          as incomplete and unusable. However, an ancient story sheds light on
          the value of seemingly insignificant elements of null data. Zero, once
          considered devoid of any use, evolved into a fundamental component of
          our number system.{" "}
          <span className="font-semibold">“Nothing means something,”</span> said
          Aryabhata, an Indian mathematician who lived over a thousand years
          ago. He incorporated zero into the decimal system and revolutionized
          counting and analysis to lay the foundation for modern science
        </p>
        <p>
          Just as symphonies contain pauses, and design has negative spaces
          (FedEx logo for example), intelligent data patterns can be discerned
          utilizing null or missing values.
        </p>
        <p>
          Spinnaker deploys responsible AI algorithms to extract value from
          incomplete data to put businesses on the path to achieving profitable
          growth.
        </p>
        <p>
          Perfect data is like world peace– a noble concept– but don't hold your
          breath for it!
        </p>
      </>
    ),
  },
  {
    title: "Hiding in plain sight: Surprising ways to use data analytics",
    slug: "hiding-in-plain-sight-surprising-ways-to-use-data-analytics",
    category: "Business",
    img: [hidingPlainSight_1, hidingPlainSight_1],
    description:
      "With the aid of emerging technology, advanced digital marketing and CRM systems, advisors can now efficiently manage their existing sales channels while developing additional revenue pathways. The power of data analytics is paving the way and unlocking hidden opportunities within an advisor’s own treasure chest of client data",
    body: (
      <>
        <p>
          With the aid of emerging technology, advanced digital marketing and
          CRM systems, advisors can now efficiently manage their existing sales
          channels while developing additional revenue pathways. The power of
          data analytics is paving the way and unlocking hidden opportunities
          within an advisor’s own treasure chest of client data
        </p>
        <p className="font-semibold">Case in point</p>
        <p>
          I was coaching the head of a mid-size brokerage firm where many
          producers were choosing to become Registered Investment Advisors. The
          firm excelled in implementing tools to seek out potential new clients.
          Things were looking promising, but overall sales were well short of
          historical figures.
        </p>
        <p>
          A deeper dive into the data revealed a sobering, yet unsurprising
          picture — almost all of the sales from the last year and a half came
          from new prospects — almost none came from existing clients. The fault
          didn’t lie with the advisors — they were continuing to stay in touch,
          personally, with their top-tier clients. Other clients, however,
          received less personalized attention in the form of holiday and
          birthday cards.
        </p>
        <p>
          When asked if advisors were using the treasure chest of client data
          collected over the years, the response was that the data was too
          overwhelming to use. They opted to stay the course, continuing with
          traditional communication with policyholders.
        </p>
        <p>
          Sherlock Holmes said it best, “The principal difficulty in the case
          lay in the fact of there being too much evidence. What was vital was
          overlaid and hidden by what was irrelevant.” In the case of these
          advisors, they did not know which client data was vital.
        </p>
        <p className="font-semibold">Worthwhile clues</p>
        <p>
          Performing data detective work can provide valuable insights into
          one’s client base and strategies for business growth. In our above
          example, we used a customer profiling tool to identify client segments
          by attributes such as demographic info and household worth. These
          attributes were analyzed by the tool using in-force and external data.
          The analysis identified policyholders who were previously
          underserviced. These policyholders had a high likelihood of purchasing
          additional coverage and advice for estate/wealth planning. We compared
          it with a database of life and wealth sales opportunities by zip code.
        </p>
        <p>
          We also identified several clients who had purchased term or final
          expense policies, sometimes dating back multiple decades. They were,
          therefore, ready for a complete financial review, including wealth and
          estate planning.
        </p>
        <p>
          A hybrid approach, formed by the fusion of scientific, data-driven
          insights with the art of advisors’ sales prowess and customer
          relationships brought great success. The brokerage firm quickly
          increased sales from current clients as conversations grew
          significantly while furthering the growth in sales from new clients.
          Advisors did their job of ensuring that the financial security
          priorities of the policyholders and their families were adequately
          addressed, with solutions catered to their needs.
        </p>
        <p>Time for your organization to do some detective work?</p>
      </>
    ),
  },
  {
    title: "Fast Fourier Transform- Innovation at its Best!",
    slug: "fast-fourier-transform-innovation-at-its-best",
    category: "Process",
    img: [fourier, fourier],
    description:
      "In common parlance, art is characterized as creative and subjective, science as empirical and data driven. Seen as opposites, these disciplines are often separated in society. \n\nAt Spinnaker, we perfectly blend the art of your management and sales expertise with the science of our data-driven insights and powerful but responsible AI algorithms to propel your business in the ever-evolving marketplace",
    body: (
      <>
        <p>
          The 1960s were marked by anti-war sentiment and the space race. No one
          noticed that an obscure algorithm called the Fourier Transform was
          undergoing metamorphosis into a crucial Cold War asset. US scientists
          were struggling to detect nuclear testing. Then two professors Cooley
          and Tukey from Cornell University stumbled upon a book belonging to
          the mathematician Gauss in the 1800s, where Gauss had written in the
          margin to quickly compute Fourier Transform – essentially a way to
          look at information from a different dimension to uncover patterns! Lo
          and behold, soon the Fast Fourier Transform was introduced to JFK’s
          science advisory committee for enhanced nuclear detection. Its
          application extended to image processing, wireless communication, and
          speech recognition – technologies that are ubiquitous today!
        </p>
        <blockquote cite="Richard Feynman" className="italic text-right">
          "In order to make progress, one must leave the door to the unknown
          ajar"
        </blockquote>
        <blockquote className="text-right">-Richard Feynman</blockquote>
        <p>
          Spinnaker Analytics firmly believes in this principle when deploying
          responsible AI in innovative products to boost our clients’ profitable
          growth
        </p>
      </>
    ),
  },
  {
    title:
      "Opposites Attract: Blending Science and Art for Insurance Distribution",
    slug: "opposites-attract-blending-science-and-art-for-insurance-distribution",
    category: "Business",
    img: [oppositesAttract, oppositesAttract],
    description:
      "In common parlance, art is characterized as creative and subjective, science as empirical and data driven. Seen as opposites, these disciplines are often separated in society. \n\nAt Spinnaker, we perfectly blend the art of your management and sales expertise with the science of our data-driven insights and powerful but responsible AI algorithms to propel your business in the ever-evolving marketplace",
    body: (
      <>
        <p>
          In common parlance, art is characterized as creative and subjective,
          science as empirical and data driven. Seen as opposites, these
          disciplines are often separated in society. But in business, science
          and art must be combined to successfully develop and implement
          solutions.
        </p>
        <p>
          At Spinnaker, we perfectly blend the art of your management and sales
          expertise with the science of our data-driven insights and powerful
          but responsible AI algorithms to propel your business in the
          ever-evolving marketplace.
        </p>
        <p>
          We have over two decades of insurance expertise and provide a
          scientific backbone to decision-making. Our powerful algorithms and
          analytic prowess assist carriers and channels to realize untapped
          sales opportunities. Whether in customer segmentation, forecasting,
          workflow prioritization, and resource optimization, we more than
          deploy solutions. We embark on a growth journey alongside our clients,
          offering consultative support and invaluable insights.
        </p>
        <p>
          This fusion enables clients to create data-driven strategies that are
          rooted in facts. With Spinnaker as a trusted partner, you can harness
          the power of data analytics and management expertise to untap
          opportunities, drive growth, and navigate the evolving insurtech
          space.
        </p>
      </>
    ),
  },
  {
    title: "Aligning e-commerce & brick-and-mortar retail strategy",
    slug: "aligning-e-commerce-and-brick-and-mortar-retail-strategy",
    category: "Business",
    img: [eCommerceBrickNewImg, eCommerceBrickNewImg2],
    description:
      "Technological innovation is transforming the retail industry in unprecedented ways. Many brick-and-mortar stores are shuttering as consumer purchasing moves online.",
    body: (
      <>
        <p>
          Technological innovation is transforming the retail industry in
          unprecedented ways. Many brick-and-mortar stores are shuttering as
          consumer purchasing moves online. At the same time, some e-retailers
          are expanding into physical storefronts. As retailers attempt to
          create seamless, integrated brand experiences, both the role and
          format of brick-and-mortar is being redefined.
        </p>
        <p>
          Historically, decisions on new physical stores were made using a
          combination of market analysis, historical benchmarks, management
          intuition, and often just imitating the competition. But this
          combination is missing a key ingredient: e-commerce analytics. By
          leveraging predictive analytics tools, retailers can use their
          customers’ digital behaviors to better quantify the volume, nature,
          and source of demand, and thus optimize storefront locations and
          formats.
        </p>
        <p>
          Spinnaker's “Store-Site Identification Solution” is an intelligent
          algorithm that identifies new geographic markets, incorporating
          historical purchase data and behavioral patterns from physical and
          online channels. Retailers can utilize these insights to optimize
          location, format, and marketing campaigns, thereby creating a seamless
          engagement with the customer — leading to profitable growth.
        </p>
      </>
    ),
  },
  {
    title: "AI Hallucinates, Just Like Humans Do",
    slug: "ai-hallucinates-just-like-humans-do",
    category: "Tech",
    img: [aIRobotImg, aIRobotImg2],
    description: `
    We’ve all met a know-it-all. You could talk to them about anything and suddenly they act as a PhD-level expert on the topic when they don’t know much, if anything, in reality. This is what “talking” to ChatGPT feels like, especially when it hallucinates.`,
    body: (
      <>
        <p>
          We’ve all met a know-it-all. You could talk to them about anything and
          suddenly they act as a PhD-level expert on the topic when they don’t
          know much, if anything, in reality. This is what “talking” to ChatGPT
          feels like, especially when it hallucinates.
        </p>
        <p>
          “Hallucinations” occur when LLMs generate responses that may sound
          coherent on the surface but are actually nonsensical or factually
          incorrect. These models are trained on vast amounts of text data. The
          algorithm learns to generate responses by predicting the next word in
          a sentence based on the patterns it observed during training. However,
          it lacks the ability to fact-check or comprehend the meaning behind
          its output, resulting in “hallucinations.”
        </p>
        <p>
          So next time you’re using ChatGPT to do your work, be sure to
          fact-check it. At Spinnaker Analytics, we are focused on responsibly
          integrating LLMs into our client-based solutions with no shortcuts, so
          you can trust that our artificial <b>intelligence</b> is actually{" "}
          <b>intelligent</b>.
        </p>
      </>
    ),
  },
  {
    title: "The difference between accuracy & precision",
    slug: "the-difference-between-accuracy-and-precision",
    category: "Process",
    img: [targetCardImg, targetCardImg, precisionaccuracyImg],
    description: `
    Conversationally, the terms “accuracy” and “precision” are often used interchangeably. Scientifically speaking, however, the terms refer to markedly different conceptual measures. “Accuracy” refers to the proximity of an estimate to the actual value, while “precision” refers to the closeness of individual estimates to each other. For example, throwing darts close to the bullseye would be accurate, while throwing darts very close to each other would be precise even if they’re nowhere near the bullseye!`,
    body: (
      <>
        <p>
          Conversationally, the terms “accuracy” and “precision” are often used
          interchangeably. Scientifically speaking, however, the terms refer to
          markedly different conceptual measures. “Accuracy” refers to the
          proximity of an estimate to the actual value, while “precision” refers
          to the closeness of individual estimates to each other. For example,
          throwing darts close to the bullseye would be accurate, while throwing
          darts very close to each other would be precise even if they’re
          nowhere near the bullseye!
        </p>
        <p>
          Companies often make the mistake of utilizing methods that seem
          powerful by virtue of their precision. At first, declaring that
          third-quarter sales will hit precisely $496.83M sounds more impressive
          and compelling than an estimated range of $462M to $465M -- that is,
          until actual sales come in at $463M.
        </p>
        <p>
          In this case, the precise estimate was precise...ly incorrect! At
          Spinnaker, we always value accuracy in predictive analytics solutions.
        </p>
      </>
    ),
  },
  {
    title: "What's the REAL problem?",
    slug: "whats-the-real-problem",
    category: "Process",
    img: [clarityImg, clarityImg2],
    description: `
    In a world moving at a thousand miles an hour, we’re always looking for the quick fix. Too often, we hastily try to suppress the distracting noise without addressing the source—the real problem. Unlike this “fire-ready-aim” approach that many businesses take, a good doctor takes note of our symptoms and diagnoses the root cause instead of merely treating the symptoms. `,
    body: (
      <>
        <p>
          In a world moving at a thousand miles an hour, we’re always looking
          for the quick fix. Too often, we hastily try to suppress the
          distracting noise without addressing the source—the real problem.
          Unlike this “fire-ready-aim” approach that many businesses take, a
          good doctor takes note of our symptoms and diagnoses the root cause
          instead of merely treating the symptoms.
        </p>
        <p>
          A client interested in increasing sales reached out to us for a
          staffing model to figure out how many additional personnel were needed
          to handle more RFP’s. When we spoke with the sales department to
          gather data, they shook their heads.
        </p>
        <p>
          “There aren’t any more RFP’s in the market,” they said. “We’re already
          seeing 95% of all RFP’s in the market, and we’re not going to get that
          last 5%.”
        </p>
        <p>
          The client didn’t have a staffing problem. They had a sales problem.
        </p>
        <p>
          In a large corporation, silos can create an assembly-line. The client
          was using a first in, first out (FIFO) method with their RFP’s instead
          of prioritizing their effort on those most likely to convert into
          sales.
        </p>
        <p>
          Instead of building them what they wanted, we built them what they
          needed—a workflow prioritizing engine. The client was able to work
          smarter by prioritizing their RFP’s and without needing to hire any
          additional personnel, increased their sales by 20%!
        </p>
        <p>
          The answer might be hiding in plain sight. Take a step back, ask
          questions and identify the root cause—the REAL problem.
        </p>
      </>
    ),
  },
  {
    title: "Feynman, simplicity & demystifying the cutting edge",
    slug: "feynman-simplicity-and-demystifying-the-cutting-edge",
    category: "Process",
    img: [rocketImg, rocketImg, feynmanImg],
    description: `
    Simplicity is touted by many, but actually achieved by few. Too many companies over-complicate concepts to sound advanced and intimidating, while explaining matters simply -- in plain English -- is far more demonstrative of understanding and transparency.
    `,
    body: (
      <>
        <p>
          Simplicity is touted by many, but actually achieved by few. Too many
          companies over-complicate concepts to sound advanced and intimidating,
          while explaining matters simply -- in plain English -- is far more
          demonstrative of understanding and transparency.
        </p>
        <p>
          When NASA’s Space Shuttle Challenger exploded 73 seconds after takeoff
          in 1986, physicist Richard Feynman not only traced back the heart of
          the disaster, but he did something other scientists could not: he
          explained the failure in 30 seconds, requiring only a glass of water
          and simple terminology. By immersing the O-rings used to seal portions
          of the aircraft in ice water, he demonstrated how the rings become
          more rigid and susceptible to breaking in freezing temperatures, as
          was the Florida forecast the night before the Challenger’s takeoff.
        </p>
        <p>
          Thus was coined the Feynman Technique, which strips down complex
          concepts to its core in a way that a child would understand, nixing
          the confusing jargon, leaving a brief and concise story from which
          everyone can gain.
        </p>
        <p>
          It’s easy to hide the gaps in your knowledge behind technical
          terminology that you know your audience won’t understand. It’s honest
          to boil the complexities down and demystify. That’s why, alongside the
          Copernicus Room and others, our office’s conference room--the room in
          which we spend the most time brainstorming and moving our company
          forward--is dubbed the Feynman Room.
        </p>
        <p>
          Just as Feynman transformed a concept as complex as the enigmatic
          behavior of subatomic particles to the simplistic visual diagram that
          revolutionized theoretical physics, our mission is to take the mystery
          out of predictive analytics. What separates Spinnaker from the pack is
          not what we have, but what we don’t--bells and whistles, red tape and
          whatever else stands between the problem and the solution.
        </p>
      </>
    ),
  },
];
