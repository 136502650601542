import React from "react";
import SubHero from "../components/SubHero";
const terms = "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/miscellaneous/termsandcondition.webp";

const TermsAndConditions = () => {
  return (
    <>
      <SubHero image={terms} title={"Terms & Conditions"} />
      <div className="container lg:w-9/12 2xl:w-7/12">
        {/* General */}
        <div className="my-10">
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed">
            You agree to the terms and conditions shown below by using
            spinnakeranalytics.com. By using the site, you expressly agree to
            these terms and conditions, which we have the right to amend at any
            moment. You will be alerted of any changes to these terms and
            conditions when you next visit and will be requested to accept the
            new ones.
          </p>
        </div>

        <hr />
        {/* Trademarks */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Trademarks
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            Spinnaker Analytics owns the trademarks, names, logos, and service
            marks featured on this website, both registered and unregistered.
            Without the explicit written consent of Spinnaker Analytics, nothing
            on this website should be considered as conferring any permission or
            right to use any trademark.
          </p>
        </div>

        <hr />
        {/* Content Ownership */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Content Ownership
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            Spinnaker Analytics owns all text, marks, logos, and other content
            on the site (including any designs, text, images, photos,
            information, data, content, and the layout, style, and organization
            thereof — collectively, the "Site Content").
          </p>
        </div>

        <hr />
        {/* Content Violations */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Content Violations
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            If you believe any of the content on this site is illegal, please
            contact us at{" "}
            <a
              href="mailto:info@spinnakeranalytics.com"
              className="text-tertiary"
            >
              info@spinnakeranalytics.com
            </a>
          </p>
        </div>

        <hr />
        {/* Licenses and External links */}
        {/* <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Licenses and External links
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            If you are interested to establish this website, please contact{" "}
            <a
              href="mailto:info@spinnakeranalytics.com"
              className="text-tertiary"
            >
              info@spinnakeranalytics.com
            </a>
          </p>
        </div>

        <hr /> */}
        {/* Accuracy of Information */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Accuracy of Information
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            Spinnaker Analytics makes every effort to offer information that is
            thought to be true but makes no assurances as to its accuracy,
            completeness, or appropriateness. The user accepts full
            responsibility for how he or she uses the website content to achieve
            the desired outcomes.
          </p>
        </div>

        <hr />
        {/* Liability */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Liability
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            Spinnaker Analytics will not be liable for any loss, liability,
            damage (whether direct, indirect, or consequential), personal
            injury, or expense of any kind that you or any third party
            (including your company) may suffer because of or that may be
            attributable, directly, or indirectly, to your access and use of the
            website, or any information contained on the website.
          </p>
        </div>

        <hr />
        {/* Inquiry Policy */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Inquiry Policy
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            All inquiries would be answered within 1 or 2 business days by our
            team. In addition, for more in-depth inquiry, the team would
            recommend a meeting.
          </p>
        </div>

        <hr />
        {/* Conflict of Terms */}
        <div className="my-10">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Conflict of Terms
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            If there is a disagreement or contradiction between the provisions
            of these website terms and conditions and any other applicable terms
            and conditions, policies, or notices that apply specifically to a
            certain section or module of the website, the other relevant terms,
            and conditions, policies, or notices that pertain specifically to
            the relevant area or module of the website shall prevail in respect
            of your use of that section or module of the website.
          </p>
        </div>

        <hr />
        {/* Applicable Laws */}
        <div className="my-10 mb-32">
          <h3 className="text-primary text-lg lg:text-3xl font-medium lg:my-6">
            Applicable Laws
          </h3>
          <p className="font-light text-sm sm:text-base lg:text-lg xl:text-xl lg:leading-relaxed xl:leading-relaxed my-4">
            In all respects, the use of this website is governed by Regulatory
            Authority, notwithstanding any laws that may apply under
            conflict-of-law considerations. The parties agree that all conflicts
            arising under this agreement will be heard exclusively by Indian
            courts, and that venue in such courts will be adequate.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
