import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController
);

const labels = ["Segment A", "Segment B", "Segment C", "Segment D"];

export const options = {
  plugins: {
    title: {
      display: false,
      text: "4Qast",
    },
    legend: {
      //   display: false,
      onClick: null,
    },
    tooltip: {
      enabled: false,
    },
  },
  animation: {
    delay: (context) => {
      let delay = 0;
      if (context.type === "data" && context.mode === "default") {
        delay = context.index * 500;
      }
      return delay;
    },
    // duration: 2000,
  },
  responsive: true,
  scales: {
    x: {
      grid: {
        color: "rgba(0, 0, 0, 0)",
      },
    },
    y: {
      display: false,
      grid: {
        color: "rgba(0, 0, 0, 0)",
      },
      min: -10,
    },
  },
};

export const data = {
  labels,
  datasets: [
    {
      type: "line",
      label: "Return Rate",
      borderColor: "rgb(7, 188 ,12)",
      borderWidth: 3,
      fill: false,
      data: [25, 30, 35, 100],
      pointStyle: false,
    },
    {
      type: "bar",
      label: "Gross Sales",
      backgroundColor: "rgb(208, 212, 202)",
      data: [100, 30, 15, 30],
      categoryPercentage: 0.9,
      barPercentage: 1.0,
    },
    {
      type: "bar",
      label: "Gross Profit",
      backgroundColor: "rgb(46, 67, 116)",
      data: [65, 18, 5, -8],
      categoryPercentage: 0.9,
      barPercentage: 1.0,
    },
  ],
};

const Returnify = () => {
  return <Chart type="bar" options={options} data={data} />;
};

export default Returnify;
