import { FaShoppingCart } from "react-icons/fa";
import { RiShieldUserFill } from "react-icons/ri";
import {
  FaUserShield,
  FaBuildingShield,
  FaHandHoldingDollar,
} from "react-icons/fa6";
import { MdOutlineWifiProtectedSetup } from "react-icons/md";

// Import Images
const nonprofit =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/industry/non-profit.webp";
const life =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/industry/life-insurance.webp";
const wealth =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/industry/wealth-management.webp";
const reinsurance =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/industry/reinsure.webp";
const employee =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/home/employee-benefits.webp";
const ecommerce =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/industry/coffee-with-work.webp";
const property =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/industry/prop-casualty.webp";

export const industryContent = [
  {
    id: 1,
    title: "Life Insurance",
    subTitle: "Life Insurance Select Deployments",
    slug: "life-insurance",
    description:
      "In today's digital age, the life insurance world is undergoing a profound transformation, driven by changing customer preferences and advancing technology. However, the fundamentals remain the same with interest rates and public health concerns defining industry success. Our steady hand is guided by decades of experience to help carriers adapt and thrive in this dynamic yet familiar landscape. Spinnaker combines institutional knowledge with data-driven insights to enhance efficiency in distribution, underwriting, operations, and claims.",
    img: life,
    content: [
      {
        id: 1,
        title: "Market Opportunity Generator",
        description:
          "Estimate granular market size and analyze client performance to develop sales potential at product, demographic, and zip-code levels",
        url: "/contact?product=market-opportunity-generator",
        icon: <RiShieldUserFill />,
      },
      {
        id: 2,
        title: "Customer Profiler",
        description: (
          <span className="lg:mt-7 inline-block">
            Analyze customer behavior patterns, identify target segments,
            develop product recommendations, and pin-point retention drivers
          </span>
        ),
        url: "/contact?product=customer-profiler",
        icon: <RiShieldUserFill />,
      },
      {
        id: 3,
        title: "Underwriting Engine",
        description: (
          <span className="lg:mt-7 inline-block">
            Assign risk ratings to prospective life customers buying
            transactional term products using minimally invasive customer and
            external data
          </span>
        ),
        url: "/contact?product=underwriting-engine",
        icon: <RiShieldUserFill />,
      },
    ],
  },
  {
    id: 2,
    title: "Property & Casualty Insurance",
    subTitle: "Property & Casualty Insurance Select Deployments",
    slug: "property-and-casualty-insurance",
    description:
      "With inflation, capital constraints, and an increasing frequency of natural disasters, leveraging existing resources and investing in new ones is critical to adapt. We support P&C carriers and brokers in M&A, integration, data analytics strategy, and solution implementation.",
    img: property,
    content: [
      {
        id: 1,
        title: "Commission Optimizer",
        description:
          "Optimize P&C contingent commission participation while meeting insurance requirements of customers using Monte Carlo simulation",
        url: "/contact?product=commission-optimizer",
        icon: <FaBuildingShield />,
      },
      {
        id: 2,
        title: "Sales Forecast",
        description:
          "Accurately and dynamically forecasts sales and volumes by business segment, product line, geography, channel, and other key attributes",
        url: "/contact?product=sales-forecast",
        icon: <FaBuildingShield />,
      },
      {
        id: 3,
        title: "Staffing Model",
        description:
          "Optimally align skillsets and determine staffing requirements by function while adjusting for complexity, seasonality, cross-training, etc.",
        url: "/contact?product=staffing-model",
        icon: <FaBuildingShield />,
      },
    ],
  },
  {
    id: 3,
    title: "Employee Benefits",
    subTitle: "Employee Benefits Select Deployments",
    slug: "employee-benefits",
    description:
      "In the steadily-growing employee benefits market, achieving significant growth can be challenging. Our data-driven approach helps you identify cross-selling opportunities, improve operational efficiency, and automate processes to grow market share.",
    img: employee,
    content: [
      {
        id: 1,
        title: "App Prioritizer",
        description:
          "Prioritize work volumes using pattern recognition algorithms to maximize yield rates, optimize workflow, and reduce unit costs",
        url: "/contact?product=app-prioritizer",
        icon: <FaUserShield />,
      },
      {
        id: 2,
        title: "Territory Design",
        description:
          "Map a territory, identify the number of agents in each market, and identify the opportunities available to them",
        url: "/contact?product=territory-design",
        icon: <FaUserShield />,
      },
      {
        id: 3,
        title: "Renewals Analytics",
        description:
          "Apply targeted renewal actions based on historical sales and claims experience data to improve profitability",
        url: "/contact?product=renewals-analytics",
        icon: <FaUserShield />,
      },
    ],
  },
  {
    id: 4,
    title: "Reinsurance",
    subTitle: "Reinsurance Select Deployments",
    slug: "reinsurance",
    description:
      "The reinsurance industry is facing increased risks as natural disasters and high payouts are running insurers dry. Spinnaker Analytics’ data-driven solutions allow clients to expertly navigate these challenging times and ultimately ensure growth.",
    img: reinsurance,
    content: [
      {
        id: 1,
        title: "Scenario Planner",
        description:
          "Assess and analyze scenarios pertaining to new initiatives to determine impact on financials, staffing, and unit costs",
        url: "/contact?product=scenario-planner",
        icon: <MdOutlineWifiProtectedSetup />,
      },
      {
        id: 2,
        title: "Reinsurance Pricing",
        description:
          "Adjust the pricing dynamically based on the characteristics and performance of the business portfolio and other relevant internal and external data sources to achieve a better alignment of risk between the primary insurer and the reinsurer.",
        url: "/contact?product=reinsurance-pricing",
        icon: <MdOutlineWifiProtectedSetup />,
      },
      {
        id: 3,
        title: "Staffing Model",
        description:
          "Optimally align skillsets and determine staffing requirements by function while adjusting for complexity, seasonality, cross-training",
        url: "/contact?product=staffing-model",
        icon: <MdOutlineWifiProtectedSetup />,
      },
    ],
  },
  {
    id: 5,
    title: "Wealth Management & Banking",
    subTitle: "Wealth Management & Banking Select Deployments",
    slug: "wealth-management-and-banking",
    description:
      "Spinnaker provides tailor-made, cutting-edge solutions within the wealth management and banking industry. Our advanced analytics and innovative technologies empower financial institutions and investors to make data-driven decisions, streamline operations, and propel growth.",
    img: wealth,
    content: [
      {
        id: 1,
        title: "Intelligent Goal Setter",
        description: (
          <span className="lg:mt-7 inline-block">
            Automatically set and assign sales goals and expense allowance by
            territory, product, and customer / size segments
          </span>
        ),
        url: "/contact?product=intelligent-goal-setter",
        icon: <FaHandHoldingDollar />,
      },
      {
        id: 2,
        title: "Market Opportunity Generator",
        description:
          "Estimate granular market size and analyze client performance to develop sales potential at product, demographic, and zip-code levels",
        url: "/contact?product=market-opportunity-generator",
        icon: <FaHandHoldingDollar />,
      },
      {
        id: 3,
        title: "Commission Optimizer",
        description: (
          <span className="lg:mt-7 inline-block">
            Improve contingent commissions income through optimal premium
            placement across carriers while serving client insurance
            requirements
          </span>
        ),
        url: "/contact?product=commission-optimizer",
        icon: <FaHandHoldingDollar />,
      },
    ],
  },
  {
    id: 6,
    title: "E-Commerce & Consumer Goods",
    subTitle: "E-Commerce & Consumer Goods Select Deployments",
    slug: "e-commerce-and-consumer-goods",
    description:
      "The COVID-19 pandemic made online shopping the new norm, with people growing accustomed to its convenience. At Spinnaker, we help navigate this rapidly developing environment by leveraging powerful AI algorithms and data analytics solutions.",
    img: ecommerce,
    content: [
      {
        id: 1,
        title: "Return Propensity",
        description: (
          <span className="lg:mt-7 2xl:mt-0 inline-block">
            Predict propensity of purchase returns to refine loyalty programs,
            improve customer expereince, and drive profitable sales
          </span>
        ),
        url: "/contact?product=return-propensity",
        icon: <FaShoppingCart />,
      },
      {
        id: 2,
        title: "Product Recommendation",
        description:
          "Recommend products to targeted customer segments and improve up-sell/cross sell probability and customer experience",
        url: "/contact?product=product-recommendation",
        icon: <FaShoppingCart />,
      },
      {
        id: 3,
        title: "Route Optimization",
        description: (
          <span className="lg:mt-7 2xl:mt-0 inline-block">
            Optimize route through dynamic and real time routing of fleets based
            on consumer patterns, cost analysis, fleet constraints, commodity,
            etc. for both forward and reverse logistics
          </span>
        ),
        url: "/contact?product=route-optimization",
        icon: <FaShoppingCart />,
      },
    ],
  },
  {
    id: 7,
    title: "Nonprofit Organizations",
    subTitle: "Nonprofit Organizations Select Deployments",
    slug: "nonprofit-organizations",
    description:
      "Data analytics is for everyone. Spinnaker proudly supports mission-driven organizations in improving operations efficiency through strategic planning and pricing optimization, ultimately supporting their positive impact on society",
    img: nonprofit,
    content: [
      {
        id: 1,
        title: "4Qast™",
        description:
          "Predict sales, revenue attendance and work volume using historical patterns and emerging trends",
        url: "",
      },
    ],
  },
];
