import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { footerMenu } from "../data/menu";
const businessAccredited =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/accredited-business.webp";

const sdo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/SDO-logo.webp";

const Footer = () => {
  const currentYear = useRef(null);

  useEffect(() => {
    const year = new Date().getFullYear();
    currentYear.current.innerText = year;
  }, []);

  return (
    <>
      <footer className="w-full bg-gray-200">
        <hr className="mt-20 lg:mt-40" />
        <div className="container">
          {/* Footer Menu Section */}
          <div className="sm:flex py-10 lg:py-8 pb-16 text-xs ">
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-10 lg:gap-20 w-full justify-start">
              {/* Menu List */}
              {footerMenu.map((item) => (
                <div
                  className={`grid gap-5 h-min ${
                    item.name === "Industries" && "lg:-ml-16"
                  }`}
                  key={item.id}
                >
                  <h2 className="text-sm lg:text-base font-medium uppercase">
                    {item.name}
                  </h2>
                  <div className="flex flex-col gap-2 text-sm lg:text-base font-light items-start justify-start w-fit">
                    {item.submenu.map((submenu) => (
                      <Link
                        to={submenu.url}
                        className="transition-all duration-300 ease-in-out pr-2 hover:text-tertiary hover:font-medium hover:pl-2 hover:pr-0"
                        key={submenu.id}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {submenu.name}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Below Section */}
          <div className="pb-16 w-full">
            <div className="flex sm:gap-20 justify-between py-5 lg:py-2">
              <div className="w-fit flex flex-col sm:flex-row gap-2">
                <a
                  href="https://www.bbb.org/us/ma/boston/profile/business-coach/spinnaker-llc-0021-91049"
                  target="_blank"
                  className="w-52 h-fit"
                >
                  <img
                    loading="lazy"
                    src={businessAccredited}
                    alt="BBB Accredited Business, since 2003"
                  />
                </a>
                <a
                  href="https://www.diversitycertification.mass.gov/BusinessDirectory/BusinessDirectorySearch.aspx"
                  target="_blank"
                  className="w-32 rounded-lg"
                >
                  <img
                    loading="lazy"
                    src={sdo}
                    alt="Supplier Diversity Office Certified"
                  />
                </a>
              </div>
              {/* Social Icons List Phone */}
              <ul className="flex flex-col gap-5 sm:hidden font-extrabold text-3xl">
                <li>
                  <a
                    href="https://www.linkedin.com/company/spinnaker-analytics"
                    target="_blank"
                    rel="noreferrer"
                    className="font-extrabold hover:text-linkedin"
                  >
                    <FaLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/Spinnakerlytics"
                    target="_blank"
                    rel="noreferrer"
                    className="font-extrabold hover:text-twitter"
                  >
                    <FaXTwitter />
                  </a>
                </li>
              </ul>
              {/* Social Icons List */}
              <ul className="hidden sm:list-none sm:flex sm:items-center sm:gap-6 justify-end font-extrabold text-3xl">
                <li>
                  <a
                    href="https://www.linkedin.com/company/spinnaker-analytics"
                    target="_blank"
                    rel="noreferrer"
                    className="font-extrabold hover:text-linkedin"
                  >
                    <FaLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/Spinnakerlytics"
                    target="_blank"
                    rel="noreferrer"
                    className="font-extrabold hover:text-twitter"
                  >
                    <FaXTwitter />
                  </a>
                </li>
              </ul>
            </div>

            {/* Email and Phone */}
            <ul className="grid gap-1 sm:list-none font-light text-gray-500 my-5 lg:mt-0 text-center">
              <li>
                E-mail:{" "}
                <a
                  href="mailto:info@spinnakeranalytics.com"
                  className="hover:text-tertiary font-medium"
                >
                  info@spinnakeranalytics.com
                </a>
              </li>
              <li>
                Phone:{" "}
                <a
                  href="tel:+1 617-303-1937"
                  className="hover:text-tertiary font-medium"
                >
                  +1 617-303-1937
                </a>
              </li>
            </ul>
            {/* Copyright */}
            <ul className="grid gap-1 sm:list-none font-light text-gray-500 mt-5 lg:mt-0 text-center">
              <li>
                © Spinnaker Analytics <span ref={currentYear}></span>
              </li>
              <li>
                Spinnaker Analytics is an affiliate of{" "}
                <a
                  href="https://www.spinnakerllc.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="font-extrabold hover:text-tertiary select-all"
                >
                  Spinnaker LLC
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
