import React, { useEffect, useState } from "react";
import { industryContent } from "../data/industries";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import Products from "../components/Products";

const Industries = () => {
  const { industrySlug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [industryData, setIndustryData] = useState({});

  useEffect(() => {
    industryContent.map((item) => {
      if (item.slug === industrySlug) {
        setIndustryData(item);
        return setIsSlugCorrect(true);
      } else {
        return null;
      }
    });
  }, [industrySlug]);

  return (
    <>
      {isSlugCorrect && industryData ? (
        <>
          {/* Hero Content */}
          <div
            className="bg-cover bg-center bg-no-repeat h-[500px] sm:h-[340px] md:h-[500px] lg:min-h-screen 2xl:min-h-[850px] relative"
            style={{
              backgroundImage: `url(${industryData.img})`,
            }}
          >
            <div className="absolute bottom-0 bg-black bg-opacity-60 p-10 w-fit">
              <h1 className="text-white swiper-no-swiping text-xl md:text-2xl lg:text-4xl 2xl:text-5xl">
                {industryData.title}
              </h1>
            </div>
          </div>
          {/* Page Content */}
          <div className="container">
            <div className="my-10 lg:my-20">
              {/* Descripton */}
              <p className="font-light text-sm md:text-lg lg:text-2xl lg:leading-relaxed mb-20">
                {industryData.description}
              </p>
              {/* Sub-Title */}
              <h2 className="text-primary text-xl lg:text-4xl font-medium my-10">
                {industryData.subTitle}
              </h2>
              {/* Products / Deployments */}
              <div
                className={`mb-10 lg:mb-20 grid sm:grid-cols-2 ${
                  industryData.content.length > 2
                    ? "lg:grid-cols-3"
                    : "lg:grid-cols-2 lg:px-10 xl:px-20"
                }  items-start gap-10`}
              >
                {industryData.content.slice(0, 4).map((item) => (
                  <Products
                    key={item.id}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                    url={item.url}
                    length={industryData.content.length}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NotFound />
        </>
      )}
    </>
  );
};

export default Industries;
