import { FaShoppingCart, FaChalkboardTeacher } from "react-icons/fa";
import { RiShieldUserFill } from "react-icons/ri";
import { IoBarChart } from "react-icons/io5";
import {
  FaUserShield,
  FaBuildingShield,
  FaHandHoldingDollar,
  FaEarthAmericas,
  FaPeopleGroup,
  FaMapLocationDot,
  FaFileInvoiceDollar,
  FaRegChartBar,
} from "react-icons/fa6";
import {
  MdOutlineWifiProtectedSetup,
  MdCurrencyExchange,
} from "react-icons/md";
import { BsPersonBoundingBox } from "react-icons/bs";
import { GiPriceTag, GiStairsGoal } from "react-icons/gi";
import { PiDetectiveFill } from "react-icons/pi";
import { GrDirections, GrOptimize } from "react-icons/gr";
import { ImLocation2 } from "react-icons/im";
import { BsStars } from "react-icons/bs";

export const menu = [
  {
    id: 1,
    name: "Home",
    url: "/",
  },
  {
    id: 3,
    name: "Industries",
    url: "/industries/life-insurance",
    submenu: [
      {
        id: 1,
        name: "Life Insurance",
        url: "/industries/life-insurance",
        icon: <RiShieldUserFill className="text-xl" />,
      },
      {
        id: 2,
        name: "Property & Casualty Insurance",
        url: "/industries/property-and-casualty-insurance",
        icon: <FaBuildingShield className="text-lg" />,
      },
      {
        id: 3,
        name: "Employee Benefits",
        url: "/industries/employee-benefits",
        icon: <FaUserShield className="text-xl" />,
      },
      {
        id: 4,
        name: "Reinsurance",
        url: "/industries/reinsurance",
        icon: <MdOutlineWifiProtectedSetup className="text-xl" />,
      },
      {
        id: 5,
        name: "Wealth Management & Banking",
        url: "/industries/wealth-management-and-banking",
        icon: <FaHandHoldingDollar className="text-xl" />,
      },
      {
        id: 6,
        name: "E-Commerce & Consumer Goods",
        url: "/industries/e-commerce-and-consumer-goods",
        icon: <FaShoppingCart className="text-lg" />,
      },
    ],
  },
  {
    id: 2,
    name: "Solutions",
    url: "/solutions/sales-prophet",
    submenu: [
      {
        id: 1,
        name: "Sales Prophet™",
        url: "/solutions/sales-prophet",
        icon: <FaEarthAmericas />,
        subsubmenu: [
          {
            id: 1,
            name: "Market Opportunity Generator",
            url: "/solutions/sales-prophet/#market-opportunity-generator",
            icon: <FaEarthAmericas />,
          },
          {
            id: 2,
            name: "Intelligent Goal Setter",
            url: "/solutions/sales-prophet/#intelligent-goal-setter",
            icon: <GiStairsGoal />,
          },
          {
            id: 3,
            name: "Territory Design",
            url: "/solutions/sales-prophet/#territory-design",
            icon: <FaMapLocationDot />,
          },
        ],
      },
      {
        id: 2,
        name: "Lead Prioritizer™",
        url: "/solutions/lead-prioritizer",
        icon: <FaChalkboardTeacher />,
        subsubmenu: [
          {
            id: 1,
            name: "App Prioritizer",
            url: "/solutions/lead-prioritizer/#app-prioritizer",
            icon: <FaChalkboardTeacher />,
          },
          {
            id: 2,
            name: "Customer Profiler",
            url: "/solutions/lead-prioritizer/#customer-profiler",
            icon: <BsPersonBoundingBox />,
          },
          {
            id: 3,
            name: "Fraud Detection",
            url: "/solutions/lead-prioritizer/#fraud-detection",
            icon: <PiDetectiveFill />,
          },
        ],
      },
      {
        id: 3,
        name: "4Qast™",
        url: "/solutions/4qast",
        icon: <IoBarChart />,
        subsubmenu: [
          {
            id: 1,
            name: "Sales Forecast",
            url: "/solutions/4qast/#sales-forecast",
            icon: <IoBarChart />,
          },
          {
            id: 2,
            name: "Unit Cost Forecasting",
            url: "/solutions/4qast/#unit-cost-forecasting",
            icon: <FaFileInvoiceDollar />,
          },
        ],
      },
      {
        id: 4,
        name: "CapMod™",
        url: "/solutions/capmod",
        icon: <FaPeopleGroup />,
        subsubmenu: [
          {
            id: 1,
            name: "Staffing Model",
            url: "/solutions/capmod/#staffing-model",
            icon: <FaPeopleGroup />,
          },
          {
            id: 2,
            name: "Productivity Assessment",
            url: "/solutions/capmod/#productivity-assessment",
            icon: <FaRegChartBar />,
          },
          {
            id: 3,
            name: "Resource Navigator",
            url: "/solutions/capmod/#resource-navigator",
            icon: <GrDirections />,
          },
        ],
      },
      {
        id: 5,
        name: "Returnify™",
        url: "/solutions/returnify",
        icon: <MdCurrencyExchange />,
        subsubmenu: [
          {
            id: 1,
            name: "Return Propensity Score",
            url: "/solutions/returnify/#return-propensity-score",
            icon: <MdCurrencyExchange />,
          },
          {
            id: 2,
            name: "Score Site Identification",
            url: "/solutions/returnify/#store-site-identification",
            icon: <ImLocation2 />,
          },
          {
            id: 3,
            name: "Loyalty Program Analytics",
            url: "/solutions/returnify/#loyalty-program-analytics",
            icon: <BsStars />,
          },
        ],
      },
      {
        id: 6,
        name: "Cartalyze™",
        url: "/solutions/cartalyze",
        icon: <GiPriceTag />,
        subsubmenu: [
          {
            id: 1,
            name: "Product Recommendation",
            url: "/solutions/cartalyze/#product-recommendation",
            icon: <GiPriceTag />,
          },
          {
            id: 2,
            name: "Optimize Pricing Strategy",
            url: "/solutions/cartalyze/#optimize-pricing-strategy",
            icon: <GrOptimize />,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "About Us",
    url: "/about-us",
    submenu: [
      {
        id: 1,
        name: "Our Approach",
        url: "/about-us/#our-approach",
        // icon: <HiChevronDoubleRight/>
      },
      {
        id: 2,
        name: "Our Clients",
        url: "/about-us/#our-clients",
        // icon: <HiChevronDoubleRight/>
      },
      {
        id: 3,
        name: "Our Team",
        url: "/about-us/#our-team",
        // icon: <HiChevronDoubleRight/>,
      },
      {
        id: 4,
        name: "Our Reach",
        url: "/about-us/#our-reach",
        // icon: <HiChevronDoubleRight/>,
      },
    ],
  },
  {
    id: 5,
    name: "Insights",
    url: "/insights",
  },
  {
    id: 6,
    name: "Contact",
    url: "/contact",
  },
];

export const footerMenu = [
  {
    id: 1,
    name: "Pages",
    submenu: [
      {
        id: 1,
        name: "Home",
        url: "/",
      },
      {
        id: 2,
        name: "Industries",
        url: "/industries/life-insurance",
      },
      {
        id: 3,
        name: "Solutions",
        url: "/solutions/sales-prophet",
      },
      {
        id: 4,
        name: "About Us",
        url: "/about-us",
      },
      {
        id: 5,
        name: "Insights",
        url: "/insights",
      },
      {
        id: 6,
        name: "Contact",
        url: "/contact",
      },
    ],
  },
  {
    id: 2,
    name: "Industries",
    submenu: [
      {
        id: 1,
        name: "Life Insurance",
        url: "/industries/life-insurance",
      },
      {
        id: 2,
        name: "Property & Casualty Insurance",
        url: "/industries/property-and-casualty-insurance",
      },
      {
        id: 3,
        name: "Employee Benefits",
        url: "/industries/employee-benefits",
      },
      {
        id: 4,
        name: "Reinsurance",
        url: "/industries/reinsurance",
      },
      {
        id: 5,
        name: "Wealth Management & Banking",
        url: "/industries/wealth-management-and-banking",
      },
      {
        id: 6,
        name: "E-Commerce & Consumer Goods",
        url: "/industries/e-commerce-and-consumer-goods",
      },
    ],
  },
  {
    id: 3,
    name: "Solutions",
    submenu: [
      {
        id: 1,
        name: "Sales Prophet™",
        url: "/solutions/sales-prophet",
      },
      {
        id: 2,
        name: "Lead Prioritizer™",
        url: "/solutions/lead-prioritizer",
      },
      {
        id: 3,
        name: "4Qast™",
        url: "/solutions/4qast",
      },
      {
        id: 4,
        name: "CapMod™",
        url: "/solutions/capmod",
      },
      {
        id: 5,
        name: "Returnify™",
        url: "/solutions/returnify",
      },
      {
        id: 6,
        name: "Cartalyze™",
        url: "/solutions/cartalyze",
      },
    ],
  },
  {
    id: 4,
    name: "About Us",
    submenu: [
      {
        id: 1,
        name: "Our Approach",
        url: "/about-us/#our-approach",
      },
      {
        id: 2,
        name: "Our Clients",
        url: "/about-us/#our-clients",
      },
      {
        id: 3,
        name: "Our Team",
        url: "/about-us/#our-team",
      },
      {
        id: 4,
        name: "Our Reach",
        url: "/about-us/#our-reach",
      },
    ],
  },
  {
    id: 5,
    name: "Legal",
    submenu: [
      {
        id: 1,
        name: "Terms & Conditions",
        url: "/terms-and-conditions",
      },
      {
        id: 2,
        name: "Privacy-policy",
        url: "privacy-policy",
      },
    ],
  },
];
