import React from "react";

const error = "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/miscellaneous/404-error.webp";

const NotFound = () => {
  return (
    <>
      <div className="container">
        <div
          className="bg-cover bg-center bg-no-repeat h-[50vh] sm:h-[100vh] md:h-[80vh] relative pointer-events-none"
          style={{
            backgroundImage: `url(${error})`,
          }}
        >
        </div>
      </div>
    </>
  );
};

export default NotFound;
