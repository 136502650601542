import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController
);

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const options = {
  plugins: {
    title: {
      display: false,
      text: "4Qast",
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  animation: {
    delay: (context) => {
      let delay = 0;
      // console.log(context);
      if (context.type === 'data' && context.mode === 'default' ) {
        delay = context.index * 200;
      }
      return delay;
    },
    // duration: 2000,
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        color: "rgba(0, 0, 0, 0)",
      },
    },
    y: {
      stacked: true,
      display: false,
      grid: {
        color: "rgba(0, 0, 0, 0)",
      },
      min: 0,
      max: 800,
      ticks: {
        stepSize: 10
      }
    },
  },
};

export const data = {
  labels,
  datasets: [
    {
      type: "line",
      label: "Dataset 1",
      borderColor: "rgb(7, 188 ,12)",
      borderWidth: 5,
      fill: false,
      data: [350, 450, 400, 650, 600, 730, 660, 600, 700, 670, 550, 510],
      pointStyle: false
    },
    {
      type: "bar",
      label: "Dataset 2",
      backgroundColor: "rgb(46, 67, 116)",
      data: [0, 0, 0, 0, 650, 780, 710, 650, 750, 720, 600, 560],
    },
    {
      type: "bar",
      label: "Dataset 3",
      backgroundColor: "rgb(208, 212, 202)",
      data: [400, 500, 450, 700],
    },
  ],
};

const Forecast = () => {
  return (
    <Chart type="bar" options={options} data={data} />
  );
};

export default Forecast;
