import React from "react";
// import { Link } from "react-router-dom";

const Card = ({ icon, title, description, url }) => {
  return (
    <>
      <div className="grid gap-5 p-6 bg-white border border-gray-200 rounded-3xl shadow-md hover:shadow-2xl transition ease-in duration-[400ms]">
        <div className="flex gap-1 items-center">
          <span className="text-primary text-xl lg:text-2xl xl:text-3xl">{icon}</span>&nbsp;
          <h3 className="text-lg xl:text-xl 2xl:text-2xl font-medium text-primary">{title}</h3>
        </div>
        <p className="text-base xl:text-lg font-light">{description}</p>
      </div>
    </>
  );
};

export default Card;
