import React from "react";

const SubHero = ({ image = "", title = "", email = "", phone = "" }) => {
  return (
    <>
      <div
        className="bg-cover bg-center bg-no-repeat h-[250px] md:h-[300px] lg:min-h-screen 2xl:min-h-[850px] grid items-center justify-center relative overflow-x-hidden"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <div className="absolute bottom-0 bg-black bg-opacity-60 w-full lg:w-4/6">
          <div className="p-5 md:p-10">
            <h1 className="text-white text-xl md:text-3xl lg:text-5xl 2xl:text-6xl lg:leading-snug 2xl:leading-snug mb-2">
              {title}
            </h1>
            {email && (
                <h2 className="text-base sm:text-xl md:text-2xl lg:text-3xl lg:leading-snug 2xl:leading-snug text-white">
                  E-mail: {" "}
                  <a href={"mailto:" + email} className="hover:text-tertiary">
                    {email}
                  </a>
                </h2>
            )}
            {phone && (
              <h2 className="mt-2 text-base sm:text-xl md:text-2xl lg:text-3xl lg:leading-snug 2xl:leading-snug text-white">
              Phone: {" "}
              <a href={"tel:" + phone} className="hover:text-tertiary">
                {phone}
              </a>
            </h2>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubHero;
