import React from "react";
import { LiaCheckSolid } from "react-icons/lia";
import { useEffect } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useState } from "react";

const SolutionSections = ({
  listItems = [],
  url = "",
  isIframe = false,
  chart = "",
  iframe = "",
}) => {
  const chartRef = useRef(null);

  const isInViewPort = useIsInViewport(chartRef);

  return (
    <>
      {/* Content */}
      <div className="lg:flex gap-5 sm:gap-10 my-5 lg:my-10 overflow-hidden w-full justify-between">
        <div className="lg:w-3/6 text-2xl">
          {listItems.map((item, index) => (
            <div
              key={index}
              className="flex gap-2 sm:gap-5 my-1 font-light mb-5 w-full"
            >
              <span className="text-sm sm:text-xl mt-1 lg:mt-2 min-w-min">
                <LiaCheckSolid />
              </span>
              <span className="w-fit text-sm sm:text-lg lg:text-2xl">
                {item}
              </span>
            </div>
          ))}
        </div>

        <div className="mt-14 lg:my-0 w-full" ref={chartRef}>
          {isIframe ? (
            <>{iframe}</>
          ) : (
            <>
              {isInViewPort && chart}
              {!isInViewPort && chart}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SolutionSections;

// Function to delay animation until user is in viewport
function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
