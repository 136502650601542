import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { IoSend, IoCheckmarkDoneSharp } from "react-icons/io5";
import { BsRobot } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import Markdown from "marked-react";
import { MdOutlineContentCopy } from "react-icons/md";
import { PiChatTeardropTextFill } from "react-icons/pi";
import { LuClipboardCheck } from "react-icons/lu";
import { FaAngleLeft, FaMinus } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { allowScroll, preventScroll } from "./Utils";

const icon =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-icon.webp";

const logo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

axios.defaults.withCredentials = true;

const ChatBot = () => {
  const baseURL = "https://spinnaker-chatbot-api.azurewebsites.net";
  // const baseURL = "http://localhost:5000";
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [load, setLoad] = useState(false);
  const [newConversation, setNewConversation] = useState(false);
  const [clipboard, setClipboard] = useState(null);
  const [botOpen, setBotOpen] = useState(false);
  const [page, setPage] = useState(null);
  const [readMore, setReadMore] = useState(null);
  const [showUpMsg, setShowUpMsg] = useState(true);
  const [showFAQs, setShowFAQs] = useState(false);

  const FAQ_Question = [
    "What is Spinnaker?",
    "What does Spinnaker do?",
    "What industries do you support?",
    "What functions do you support?",
  ];

  const handleOnChange = (e) => {
    setMessage(e.target.value);
  };

  const startNewConversation = () => {
    axios
      .delete(`${baseURL}/api/user/clear-chat-history`)
      .then((res) => {
        setMessage("");
        setLoad(false);
        setChatHistory([]);
        setNewConversation(false);
        setPage("SAGE");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const scrollToBottom = useRef(null);

  const onMessageSend = (e, option = false) => {
    if (option) {
      setMessage("");
      setPage("SAGE");
      setNewConversation(false);
    } else {
      e.preventDefault();
    }

    if (message || option) {
      setChatHistory((prevChat) => [
        ...prevChat,
        {
          chat: option ? option : message,
          time: new Date().toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          }),
        },
      ]);
      setLoad(true);
      setMessage("");
      setShowFAQs(false);
      axios
        .post(`${baseURL}/api/pdf/chat`, {
          query: option ? option : message,
        })
        .then((res) => {
          // console.log(res.data);
          setLoad(false);
          if (res?.data?.response === "") {
            const error = "Something went wrong! Empty message from bot";
            setChatHistory((prevChat) => [
              ...prevChat,
              {
                chat: error,
                time: new Date().toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                }),
              },
            ]);
          } else {
            setChatHistory((prevChat) => [
              ...prevChat,
              {
                chat: res?.data?.response.chat,
                time: new Date(res?.data?.response.time).toLocaleTimeString(
                  "en-US",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                ),
              },
            ]);

            if (res?.data?.response?.is_ignore) {
              setShowFAQs(true);
            }
          }
        })
        .catch((err) => {
          // const error = "Something went wrong! Please try again later";
          console.log(err);
          const error = err?.response?.data?.error_response.chat;
          setLoad(false);

          if (error) {
            setChatHistory((prevChat) => [
              ...prevChat,
              {
                chat: error,
                time: new Date(
                  err?.response?.data?.error_response.time
                ).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                }),
              },
            ]);
          } else {
            setChatHistory((prevChat) => [
              ...prevChat,
              {
                chat: "Chatbot is down for the moment.",
                time: new Date().toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                }),
              },
            ]);
          }

          console.log(err);
        });
    }
  };

  useEffect(() => {
    // console.log(scrollToBottom?.current);
    scrollToBottom?.current?.scrollIntoView({
      behavior: "smooth",
      bottom: 0,
    });
  }, [chatHistory, page]);

  const messageInput = useRef(null);

  // input box focus
  useEffect(() => {
    if (botOpen) {
      messageInput?.current?.focus();
    }
  }, [botOpen, chatHistory]);

  // Chat history axios call to fetch old history upon reload
  useEffect(() => {
    axios
      .get(`${baseURL}/api/user/chat-history`)
      .then(async ({ data }) => {
        const history = await data.chatHistory.map((item) => {
          return {
            chat: item.chat,
            time: new Date(item.time).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
            }),
          };
        });

        setChatHistory(history);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  // copy to clipboard
  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
  };

  // Copy to clipboard show only for 5 seconds
  useEffect(() => {
    if (clipboard) {
      setTimeout(() => {
        setClipboard(null);
      }, 5000);
    }
  }, [clipboard]);

  return (
    <>
      {/* ChatBot */}
      <div
        className={`fixed md:flex md:flex-col md:gap-1 items-end md:justify-end z-50 ${
          botOpen
            ? "md:bottom-10 md:right-10 h-full lg:h-fit w-full md:w-fit"
            : "md:w-fit bottom-0 md:bottom-10 md:right-10 h-20 md:h-0"
        }`}
      >
        {/* If bot is closed */}
        {!botOpen && showUpMsg && (
          <>
            {chatHistory.length === 0 && (
              <div
                onClick={() => {
                  setBotOpen(true);
                  preventScroll();
                  setPage("SAGE");
                }}
                className="z-5 bg-white shadow-lg w-48 py-2 px-4 bottom-5 md:bottom-[110px] right-24 lg:right-10 absolute md:fixed rounded-md temporary-bounce animate-bounce cursor-pointer"
              >
                <p className="text-xs py-2 leading-1">
                  <strong>Hi there!👋</strong> I'm SAGE, Spinnaker Analytics'
                  Guided Explorer. How can I help you today?
                </p>
              </div>
            )}

            {chatHistory.length === 0 && (
              <span
                onClick={() => setShowUpMsg(false)}
                className="bottom-24 md:bottom-52 bg-slate-200 z-5 right-24 lg:right-10 absolute md:fixed rounded-full p-[2px] cursor-pointer temporary-bounce animate-bounce"
              >
                <IoClose className="text-slate-700 text-sm m-auto" />
              </span>
            )}
          </>
        )}
        {!botOpen && chatHistory.length < 1 && (
          <span className="rounded-full text-white bg-tertiary w-5 h-5 z-10 text-xs text-center absolute md:fixed bottom-16 md:bottom-20 right-4 md:right-10 temporary-bounce animate-bounce">
            !
          </span>
        )}
        {/* Bot toggle button */}
        <div
          className={`rounded-full cursor-pointer ${
            botOpen
              ? "absolute z-50 top-5 right-5 md:right-0 md:top-0 md:relative md:bg-gray-500 md:shadow-gray-600/50 md:opacity-75 hover:opacity-100 md:px-4 md:py-2 md:drop-shadow-lg"
              : "absolute md:fixed bottom-5 right-5 md:bottom-10 md:right-10 rounded-tr-3xl bg-gradient-to-br from-sky-700 via-sky-900 to-slate-900 shadow-primary/50 p-4 drop-shadow-lg"
          } `}
          onClick={() => {
            setBotOpen(!botOpen);
            setShowUpMsg(false);
            allowScroll();
            setPage("SAGE");
          }}
        >
          {botOpen ? (
            <>
              <IoClose className="text-white text-lg m-auto" />
            </>
          ) : (
            <div>
              <span className="relative flex">
                <span
                  className={`absolute inline-flex h-full w-full rounded-full ${
                    chatHistory.length === 0 &&
                    botOpen === false &&
                    "animate-ping bg-cyan-400 opacity-75"
                  }`}
                ></span>
                <PiChatTeardropTextFill className="text-white text-3xl" />
              </span>
            </div>
          )}
        </div>

        {/* Bot Content */}
        <div
          className={`bot drop-shadow-lg z-50 overflow-hidden h-full md:h-fit max-h-screen ${
            botOpen ? "active" : "inactive"
          }`}
        >
          {/* <ChatBot starts here */}
          <div className="bg-gray-100 text-gray-600 md:rounded-md flex text-sm flex-col justify-between overflow-hidden max-h-full h-full md:h-[60vh] lg:h-[75vh] 2xl:h-[70vh] relative w-screen md:w-96">
            <div className="h-10"></div>

            {/* Chatbot title and options */}
            <div
              className={`absolute w-full p-5 lg:py-2 xl:py-5 bg-gradient-to-br from-sky-700 via-sky-900 to-slate-900 select-none text-white rounded-b-3xl ${
                page ? "h-2/5" : "h-2/6"
              }`}
            >
              {page ? (
                <div className="flex gap-3 items-center">
                  <div
                    onClick={() => setPage(null)}
                    className="bg-white bg-opacity-50 hover:bg-opacity-100  hover:text-black p-1 h-fit flex items-center cursor-pointer justify-center rounded-sm"
                    title="Go back"
                  >
                    <FaAngleLeft className="text-lg" />
                  </div>
                  <div className="flex items-center gap-3 py-2">
                    <BsRobot className="text-3xl" />
                    <p className="text-lg">I am SAGE</p>
                  </div>
                </div>
              ) : (
                <div className="flex gap-2 flex-col">
                  <img
                    src={logo}
                    alt="logo"
                    className="w-48 invert grayscale brightness-0 rounded-xl"
                  />
                </div>
              )}
            </div>

            {/* Chat div block */}
            <div
              className={`p-3 flex flex-col w-full gap-2 justify-end relative h-[85%]`}
            >
              {/* Chat Space */}
              <div className="chat h-full overflow-y-auto custom_scroll rounded-lg mb-2 relative w-full overflow-auto">
                <div
                  className={`min-h-full flex flex-col relative ${
                    page ? "justify-end bg-white shadow-inner" : ""
                  }`}
                >
                  {page ? (
                    <>
                      {/* Options for user before starting chats */}
                      {page === "SAGE" && (
                        <div className="flex flex-col my-2 p-5">
                          <div className="mb-2 w-[95%]">
                            <div className="py-2 px-2 bg-gray-100 rounded-tl-md rounded-xl rounded-br-md">
                              <p className="text-sm py-2 leading-1">
                                <strong>Hi there!👋</strong> I'm SAGE, Spinnaker
                                Analytics' Guided Explorer. How can I help you
                                today?
                              </p>
                            </div>
                            <div className="py-2 mt-2 px-2 rounded-md">
                              <div className="py-2 px-0 font-semibold">
                                Not sure? Here are a few questions to get
                                started
                              </div>
                              {/* <hr /> */}
                              {/* FAQ Blocks */}
                              <div className="">
                                {FAQ_Question.map((question, index) => (
                                  <div
                                    onClick={(e) => {
                                      if (!load) {
                                        onMessageSend(e, question);
                                      }
                                    }}
                                    className={`py-2 px-2 rounded-md  text-xs 2xl:text-sm border-2 mt-2  ${
                                      load
                                        ? "border-gray-200"
                                        : "border-[#2E7E9B] hover:bg-[#E7F2F7] cursor-pointer"
                                    } `}
                                    key={index}
                                  >
                                    {question}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>

                          {chatHistory.map((history, index) => (
                            <div key={index}>
                              {/* Chat Area */}
                              <div
                                className={`flex flex-col gap-1 justify-end first:mt-0 my-3 w-full ${
                                  index % 2 === 0 ? "items-end" : ""
                                }`}
                              >
                                {/* Bot or You chat title */}
                                <div className="select-none text-[11px] text-primary">
                                  {index % 2 === 0 ? (
                                    <div className="flex gap-1 items-center">
                                      <FiUser className="text-lg" />
                                      <span>You</span>
                                    </div>
                                  ) : (
                                    <div className="flex gap-2 items-center">
                                      <span>SAGE </span>
                                      <BsRobot className="text-lg" />
                                    </div>
                                  )}
                                </div>
                                {/* Chats */}
                                <div className="first-letter:capitalize break-words max-w-[85%] w-fit relative">
                                  <div
                                    className={`p-3 px-4 rounded-2xl response ${
                                      index % 2 === 0
                                        ? "bg-gray-200 rounded-tr-sm"
                                        : "bg-gray-100 rounded-tl-sm"
                                    }`}
                                  >
                                    {/* <Markdown>{history.chat}</Markdown> */}
                                    {history?.chat === readMore ? (
                                      <>
                                        <Markdown>{history?.chat}</Markdown>
                                      </>
                                    ) : (
                                      <>
                                        <Markdown>
                                          {history?.chat?.substring(0, 200) +
                                            (history?.chat.length >= 200
                                              ? "..."
                                              : "")}
                                        </Markdown>
                                      </>
                                    )}
                                    {/* chat meta data */}
                                    <div
                                      className={`flex items-start gap-1 mt-2 ${
                                        index % 2 !== 0 &&
                                        history.chat.length >= 200
                                          ? "justify-between"
                                          : "justify-end"
                                      }`}
                                    >
                                      {/* Read more link */}
                                      {history.chat.length >= 200 &&
                                        index % 2 !== 0 &&
                                        readMore !== history.chat && (
                                          <div
                                            className="cursor-pointer hover:underline text-xs"
                                            onClick={() =>
                                              setReadMore(history.chat)
                                            }
                                          >
                                            Read More
                                          </div>
                                        )}
                                      {/* Read less link */}
                                      {index % 2 !== 0 &&
                                        readMore === history.chat &&
                                        history.chat.length >= 200 && (
                                          <div
                                            className="cursor-pointer hover:underline text-xs"
                                            onClick={() => setReadMore(null)}
                                          >
                                            Read Less
                                          </div>
                                        )}
                                      <div className="flex justify-end items-center gap-1">
                                        {/* Time stamp */}
                                        <span className="text-[11px] px-1 text-gray-500 select-none">
                                          {history.time}
                                        </span>
                                        {/* Copy to clipboard */}
                                        {index % 2 !== 0 ? (
                                          <div className="cursor-pointer mb-1 text-gray-500">
                                            {clipboard === history.chat ? (
                                              <LuClipboardCheck
                                                title="Copied to clipboard!"
                                                className="text-gray-700"
                                              />
                                            ) : (
                                              <MdOutlineContentCopy
                                                title="Copy to clipboard!"
                                                onClick={() => {
                                                  copyToClipboard(history.chat);
                                                  setClipboard(history.chat);
                                                }}
                                              />
                                            )}
                                          </div>
                                        ) : (
                                          // sent check mark
                                          <IoCheckmarkDoneSharp className="text-cyan-600" />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {showFAQs &&
                                    index === chatHistory.length - 1 && (
                                      <div className="py-2 mt-2 px-2 rounded-md">
                                        <div className="py-2 px-0 font-semibold">
                                          Not sure? Here are a few questions to
                                          get started
                                        </div>
                                        {/* <hr /> */}

                                        <div className="">
                                          {FAQ_Question.map(
                                            (question, index) => (
                                              <div
                                                onClick={(e) => {
                                                  if (!load) {
                                                    onMessageSend(e, question);
                                                  }
                                                }}
                                                className="py-2 px-2 rounded-md cursor-pointer text-xs 2xl:text-sm border-2 mt-2 border-[#2E7E9B] hover:bg-[#E7F2F7]"
                                                key={index}
                                              >
                                                {question}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                                <div ref={scrollToBottom}></div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  ) : (
                    // Showing this block when chats appear
                    <div className="p-2 flex flex-col gap-3 w-fit overflow-hidden select-none">
                      {/* chat with the bot option */}
                      <div className="bg-white flex flex-col gap-2 rounded-md p-1 shadow-md">
                        <div className="p-2 font-semibold">Ask SAGE</div>
                        <hr />
                        <div
                          className="hover:bg-gray-100 p-3 rounded-md cursor-pointer flex gap-3 items-center"
                          onClick={() => {
                            setPage("SAGE");
                            setNewConversation(false);
                          }}
                        >
                          <BsRobot className="text-3xl" />
                          <div className="flex flex-col gap-1 relative">
                            <p className="font-semibold">SAGE</p>
                            {chatHistory.length === 0 ? (
                              <p className="text-xs">
                                Spinnaker Analytics's Guided Explorer
                              </p>
                            ) : (
                              <div className="text-xs text-ellipsis overflow-hidden">
                                {chatHistory.length > 1 &&
                                  chatHistory[
                                    chatHistory.length - 1
                                  ]?.chat.substring(0, 30)}
                                {chatHistory[chatHistory.length - 1]?.chat
                                  .length >= 30 && "..."}
                              </div>
                            )}
                            {chatHistory.length === 0 && (
                              <div className="absolute animate-bounce -top-3 -right-6 h-5 w-fit flex items-center justify-center rounded-full text-xs bg-red-500 text-white drop-shadow-lg shadow-black p-2">
                                <p className="">1</p>
                              </div>
                            )}
                          </div>
                        </div>
                        {/* New Conversation */}
                        {chatHistory.length > 1 && (
                          <>
                            <div
                              onClick={() =>
                                setNewConversation(!newConversation)
                              }
                              className="hover:bg-gray-100 p-3 rounded-md cursor-pointer flex gap-3 items-center"
                            >
                              {newConversation ? (
                                <>
                                  <FaMinus />
                                  <span>
                                    Continue with existing conversation
                                  </span>
                                </>
                              ) : (
                                <>
                                  <FaPlus />
                                  <span>Start a new conversation</span>
                                </>
                              )}
                            </div>
                            {/* Start new conversation*/}
                            {newConversation && (
                              <div className="p-3 rounded-md cursor-pointer text-center w-full flex flex-col gap-3 items-center text-red-400">
                                This action will clear existing chat data
                                <div
                                  onClick={() => startNewConversation()}
                                  className="bg-gray-100 text-black rounded-lg p-3 w-full hover:bg-gray-200"
                                >
                                  New Conversation
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {/* Not Sure option */}
                      <div className="bg-white sticky h-full flex flex-col gap-2 rounded-md p-1 shadow-md">
                        <div className="p-2 font-semibold">
                          Not sure? Here are a few questions to get started
                        </div>
                        <hr />
                        {/* FAQ Section */}
                        <div className="h-fit md:h-[160px] overflow-y-auto chat custom_scroll">
                          {FAQ_Question.map((question, index) => (
                            <div
                              onClick={(e) => {
                                if (!load) {
                                  onMessageSend(e, question);
                                }
                              }}
                              className="hover:bg-gray-100 p-3 rounded-md cursor-pointer text-xs 2xl:text-sm"
                              key={index}
                            >
                              {question}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="absolute bottom-0 flex justify-center items-end overflow-hidden w-fit left-20 text-xs">
                        Powered by <img src={icon} alt="logo" className="w-5" />{" "}
                        <span className="font-semibold text-primary">
                          Spinnaker Analytics
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Message send, type and load*/}
              {page === "SAGE" && (
                <div className="bg-white h-fit rounded-lg w-full transition-all relative duration-500 ease-in-out">
                  {load ? (
                    <div className="p-6 pt-7">
                      <div className="dot-flashing"></div>
                    </div>
                  ) : (
                    <form action="" onSubmit={(e) => onMessageSend(e)}>
                      <div className="flex gap-5 p-5 py-3 placeholder:select-none">
                        <input
                          type="text"
                          name="message"
                          placeholder="Ask me anything.."
                          className="w-full "
                          onChange={(e) => handleOnChange(e)}
                          value={message || ""}
                          ref={messageInput}
                          autoComplete="off"
                        />
                        <div
                          className="cursor-pointer rounded-full bg-gradient-to-br from-sky-700 via-sky-900 to-slate-900 p-3"
                          onClick={(e) => onMessageSend(e)}
                        >
                          <IoSend className="text-xl text-white" />
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBot;
