// Functions created to allow and disallow scroll to work when working on an existing layer over body
const body = document.body;

// prevents scroll in a body
export const preventScroll = () => {
  body.classList.add("overflow-hidden");
  body.classList.remove("overflow-y-auto");
};

// allows scroll in body
export const allowScroll = () => {
  body.classList.add("overflow-y-auto");
  body.classList.remove("overflow-hidden");
};
