import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Hero from "./Hero";

import messyData from "../images/messyData.png";
import hiddenValue from "../images/hiddenValue.png";
import panic from "../images/panic.png";
import dataMaturity from "../images/dataMaturity.png";
import marketLeadership from "../images/marketLeadership.png";

// Import Images
// const unlockHiddenValue =
//   "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/home/unlock-hidden-value.webp";
// const marketLeadership =
//   "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/home/market-leadership.webp";
// const spinnaker_ad =
//   "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/home/spinnaker-ad.webp";
// const data_maturity =
//   "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/home/messydata-noproblem.webp";

const HomeCarousals = () => {
  return (
    <>
      <Swiper
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        modules={[Autoplay, Pagination, Navigation]}
        slidesPerView={1}
        speed={10}
        slideToClickedSlide={true}
      >
        <SwiperSlide>
          <Hero image={panic} title="" description={""} />
        </SwiperSlide>
        <SwiperSlide>
          <Hero image={messyData} title="" description={""} />
        </SwiperSlide>
        <SwiperSlide>
          <Hero image={hiddenValue} title="" description={""} />
        </SwiperSlide>
        <SwiperSlide>
          <Hero image={marketLeadership} title="" description={""} />
        </SwiperSlide>
        <SwiperSlide>
          <Hero image={dataMaturity} title="" description={""} />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default HomeCarousals;
