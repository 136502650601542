import React, { useEffect, useRef } from "react";
import SubHero from "../components/SubHero";
import EmailForm from "../components/EmailForm";
import { contactData } from "../data/contactPageData";
import ContactCard from "../components/ContactCard";
import { useLocation } from "react-router-dom";

// Import Images
const contact =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/home/boston.webp";

const Contact = () => {
  const location = useLocation();
  const scrollToContact = useRef(null);

  useEffect(() => {
    if (window.location.search.includes("?") && scrollToContact.current) {
      scrollToContact.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [location]);

  return (
    <>
      <SubHero
        image={contact}
        title={"Contact Us"}
        email={"info@spinnakeranalytics.com"}
        phone={"+1 617-303-1937"}
      />

      <div className="container">
        <div className="my-10 lg:my-20 lg:grid lg:gap-12 lg:grid-cols-3 h-fit">
          <div>
            <div className="grid gap-5 font-light text-sm sm:text-base lg:text-lg xl:text-xl">
              <p>We thank you for your interest in our analytics tools.</p>
              <p>
                If you would like a demonstration, or want to learn more about
                how Spinnaker Analytics can grow your business, complete the
                form and we will get in touch with you.
              </p>
              <p>Or, contact us directly at any of our offices below.</p>
            </div>
          </div>
          <div className="lg:col-span-2 mt-12 lg:mt-0" ref={scrollToContact}>
            <EmailForm />
          </div>
        </div>
        <div className="mt-10">
          <h3 className="text-primary text-xl lg:text-3xl font-medium my-10">
            Our Offices
          </h3>
          <div className="grid sm:grid-cols-2 gap-6 mb-32">
            {contactData.map((item) => (
              <ContactCard key={item.id} data={item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
