import React from "react";

const BlogCard = ({
  blogImage,
  blogAlt,
  category,
  title,
  description,
  id,
  navigate,
  slug,
}) => {
  return (
    <>
      <div
        key={id}
        onClick={() => navigate(slug)}
        className="relative border rounded-xl overflow-hidden cursor-pointer shadow-md hover:shadow-xl transition ease-in duration-300 mb-10 h-[22rem]"
      >
        <img
          src={blogImage}
          alt={blogAlt}
          className="h-40 2xl:h-48 w-full object-cover"
        />
        <p className="absolute swiper-no-swiping top-3 left-3 p-2 bg-white shadow-xl rounded-sm uppercase text-sm font-bold text-primary">
          {category}
        </p>
        <div className="flex flex-col gap-2 p-3">
          <p className="swiper-no-swiping text-base font-semibold text-primary">
            {title}
          </p>
          <p className="text-sm swiper-no-swiping">
            {description.split(" ").slice(0, 12).join(" ") + " ..."}
          </p>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
