import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  BarController
);

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const options = {
  plugins: {
    title: {
      display: false,
      text: "4Qast",
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  animation: {
    delay: (context) => {
      let delay = 0;
      if (context.type === "data" && context.mode === "default") {
        delay = context.index * 200;
      }
      return delay;
    },
    // duration: 2000,
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        color: "rgba(0, 0, 0, 0)",
      },
    },
    y: {
      stacked: true,
      display: false,
      grid: {
        color: "rgba(0, 0, 0, 0)",
      },
      min: 0,
    },
  },
};

const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
export const data = {
  labels,
  datasets: [
    {
      type: "line",
      label: "Dataset 6",
      borderColor: "red",
      borderWidth: 2,
      fill: false,
      data: [73, NaN, 73, NaN, 73, NaN, 73, NaN, 73, NaN, NaN, 73],
      segment: {
        borderDash: ctx => skipped(ctx, [6, 6]),
      },
      spanGaps: true,
      pointStyle: false
    },
    {
      type: "line",
      label: "Dataset 1",
      borderColor: "rgb(7, 188 ,12)",
      borderWidth: 2,
      fill: false,
      data: [17, NaN, 17, NaN, 17, NaN, 17, NaN, 17, NaN, NaN, 17],
      segment: {
        borderDash: ctx => skipped(ctx, [6, 6]),
      },
      spanGaps: true,
      pointStyle: false
    },
    {
      type: "bar",
      label: "Dataset 2",
      // backgroundColor: "rgb(28, 51, 103)",
      backgroundColor: "rgb(46, 67, 116)",
      data: [20, 26, 28, 30, 27, 25, 30, 40, 50, 40, 22, 45],
    },
    {
      type: "bar",
      label: "Dataset 3",
      backgroundColor: "rgb(46, 67, 116, 0.8)",
      data: [22, 22, 20, 18, 17, 15, 15, 15, 17, 15, 15, 20],
    },
    {
      type: "bar",
      label: "Dataset 4",
      backgroundColor: "rgb(46, 67, 116, 0.5)",
      data: [15, 15, 10, 6, 8, 6, 6, 10, 4, 10, 5, 10],
    },
    {
      type: "bar",
      label: "Dataset 5",
      backgroundColor: "rgb(208, 212, 202, 0.5)",
      data: [22, 22, 19, 17, 15, 12, 20, 15, 12, 15, 10, 15],
    },
  ],
};

const CapMod = () => {
  return (
    <Chart type="bar" options={options} data={data} />
  );
};

export default CapMod;
